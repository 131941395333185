import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Backdrop,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeCheckoutButton = ({
  userId,
  priceId,
  price,
  title,
  description,
  paymentPhase,
  hasPayment,
  name,
  formattedPhone,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCheckout = async () => {
    setLoading(true);
    const stripe = await stripePromise;
    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(
      functions,
      "createCheckoutSession"
    );

    try {
      const { data } = await createCheckoutSession({
        priceId,
        successUrl: "https://mastermindmembers.net/payment/success",
        cancelUrl: "https://mastermindmembers.net/payment/cancel",
        userId,
        paymentPhase,
      });

      await stripe.redirectToCheckout({ sessionId: data.id });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={4}
      sx={{ mt: 5 }}
    >
      <Card
        sx={{
          boxShadow: 3,
          padding: "16px",
          maxWidth: 400,
          backgroundColor: hasPayment ? "rgba(0, 0, 0, 0.1)" : "white",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            {description}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: hasPayment ? "text.secondary" : "primary.main",
              fontWeight: "bold",
            }}
          >
            {/* {price} */}
            {!hasPayment && (
              <>
                ¥
                {Number(
                  parseFloat(price.replace(/[^0-9.-]+/g, "")) * 1.1
                ).toLocaleString()}
              </>
            )}
          </Typography>

          {/* 条件付きで追加のテキストを表示 */}
          {/* {price === "¥ 28,000" && (
            <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
              12/22からは ¥ 42900
            </Typography>
          )} */}

          {hasPayment && (
            <Typography variant="body2" sx={{ color: "red", marginTop: 2 }}>
              既に支払い済みです。
            </Typography>
          )}

          {!hasPayment && (
            <>
              <Divider sx={{ marginY: 2 }} />
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", marginBottom: 1 }}
              >
                銀行振込の情報
              </Typography>

              <Typography variant="body2">
                銀行名: GMOあおぞらネット銀行
              </Typography>
              <Typography variant="body2">支店名: 法人第二営業部</Typography>
              <Typography variant="body2">種目：普通</Typography>
              <Typography variant="body2">口座番号: 1007963</Typography>
              <Typography variant="body2">
                口座名義: ファミュニティリンク株式会社イベント
              </Typography>

              {/* <Typography variant="body2">銀行名: PayPay銀行</Typography>
              <Typography variant="body2">
                支店名: ビジネス営業部 (005)
              </Typography>
              <Typography variant="body2">口座番号: 1306661</Typography>
              <Typography variant="body2">
                口座名義: ファミュニティリンク株式会社 地球開発事業部
              </Typography> */}

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  color: "red",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                振込名義: {formattedPhone} お名前
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  color: "red",
                  textAlign: "center",
                  fontSize: "1rem",
                  backgroundColor: "yellow",
                  padding: "8px",
                  borderRadius: "4px",
                  display: "inline-block",
                  marginTop: "16px",
                }}
              >
                振込名義が異なりますと、入金確認に支障が生じる可能性がございます。
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  color: hasPayment ? "text.secondary" : "primary.main",
                  fontWeight: "bold",
                }}
              >
                {/* { Number(price * 1.1)}  */}¥
                {Number(
                  parseFloat(price.replace(/[^0-9.-]+/g, "")) * 1.1
                ).toLocaleString()}
              </Typography>

              {/* 条件付きで追加のテキストを表示 */}
              {/* {price === "¥ 28,000" && (
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", mt: 1 }}
                >
                  12/22からは ¥ 42,900
                </Typography>
              )} */}

              <Divider
                sx={{ marginY: 2 }}
                children={
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    or
                  </Typography>
                }
              />

              {/* 支払いボタン */}
              {!hasPayment && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCheckout}
                  disabled={loading}
                  sx={{
                    width: "100%",
                    maxWidth: "300px",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "8px",
                    textTransform: "none",
                  }}
                >
                  クレジットカードで支払う
                </Button>
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* ローディング中のオーバーレイ */}
      <Backdrop
        open={loading}
        sx={{
          zIndex: 1300,
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            支払いページ準備中...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default StripeCheckoutButton;
