import React, { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import StripeCheckoutButton from "./StripeCheckoutButton";
import { db, auth } from "../api/firebase";

const prices = [
  { price: "¥28,000", priceId: "price_1QTO2gCVGZwNG78CK836TWT5" },
  { price: "¥39,000", priceId: "price_1QTPD8CVGZwNG78CY174oIbH" },
  { price: "¥55,000", priceId: "price_1QTPFOCVGZwNG78CCeWid1No" },
  { price: "¥50", priceId: "price_1QThcjCVGZwNG78CLaztAHHh" },
];

const PaymentContainer = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // `unsubscribe` を保持する変数
    let unsubscribe;

    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("No authenticated user found.");
      setLoading(false);
      return;
    }

    const userId = currentUser.uid;
    const userDocRef = doc(db, "users", userId);

    // Firestore ドキュメントの変更をリアルタイムで監視
    unsubscribe = onSnapshot(
      userDocRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.data();
          setUserData({ ...userData, userId });
        } else {
          console.error(`User document not found for uid: ${userId}`);
          setUserData(null);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error setting up snapshot listener:", error);
        setLoading(false);
      }
    );

    // クリーンアップ関数で監視を解除
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>ユーザー情報を取得できませんでした。</div>;
  }

  const paymentPhase = "2"; // 支払いフェーズを固定
  const title = "マスターマインドメンバーズ２期";
  const description = "マスターマインドの素晴らしい仲間たちと夢を叶える旅のスタート";

  const priceKey = "secondPhasePrice";
  const cutoffDate = new Date("2024-12-01");
  const userCreatedAt = userData.createdAt.toDate();

  let selectedPrice = prices[2]; // デフォルト値

  if (userCreatedAt <= cutoffDate) {
    selectedPrice = prices[1]; // ¥28,000の商品を選択
  } else if (userData[priceKey]) {
    const matchedPrice = prices.find((price) => price.price === userData[priceKey]);
    if (matchedPrice) {
      selectedPrice = matchedPrice;
    }
  }

  const hasPayment = userData.payments?.some(
    (payment) => payment.paymentPhase === paymentPhase
  );

  const formattedPhone = userData.registrationPhone
    ? userData.registrationPhone.slice(-4)
    : "----";
  const fullName = userData.name || "未登録";

  return (
    <div>
      <StripeCheckoutButton
        userId={userData.userId}
        priceId={selectedPrice.priceId}
        price={selectedPrice.price}
        title={title}
        description={description}
        paymentPhase={paymentPhase}
        hasPayment={hasPayment}
        formattedPhone={formattedPhone}
      />
    </div>
  );
};

export default PaymentContainer;
