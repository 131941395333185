import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Typography,
  Avatar,
} from "@mui/material";
import { db, auth } from "../api/firebase"; // Adjust the path as necessary
import { addDoc, collection, doc, getDoc, serverTimestamp } from "firebase/firestore";

const QuestionDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  // ユーザー情報を取得する
  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setUserInfo(userDoc.data());
          } else {
            console.error("ユーザー情報が見つかりません。");
          }
        } catch (error) {
          console.error("ユーザー情報の取得に失敗しました:", error);
        }
      }
    };

    fetchUserInfo();
  }, []);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setQuestion("");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSendQuestion = async () => {
    const user = auth.currentUser;

    if (!user || !userInfo) {
      setSnackbarMessage("ユーザー情報が取得できません。ログインしてください。");
      setSnackbarOpen(true);
      return;
    }

    if (!question.trim()) {
      setSnackbarMessage("質問内容を入力してください。");
      setSnackbarOpen(true);
      return;
    }

    try {
      await addDoc(collection(db, "questions20250113"), {
        question,
        userId: user.uid,
        userName: userInfo.name || "匿名ユーザー",
        userAvatar: userInfo.avatar || "",
        email: user.email || "不明",
        createdAt: serverTimestamp(),
      });

      setSnackbarMessage("質問が送信されました。");
      setSnackbarOpen(true);
      handleCloseDialog();
    } catch (error) {
      console.error("質問の送信中にエラーが発生しました:", error);
      setSnackbarMessage("質問の送信中にエラーが発生しました。もう一度お試しください。");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        className="mt-4"
      >
        問い合わせ
      </Button>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>1月13日の質問を受付</DialogTitle>
        <DialogContent>
          {userInfo && (
            <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
              <Avatar src={userInfo.avatar} alt="User Avatar" />
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                {userInfo.name || "匿名ユーザー"}
              </Typography>
            </div>
          )}
          <Typography variant="body2" color="textSecondary" gutterBottom>
            ご質問を入力し、「送信」ボタンをクリックしてください。
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="質問内容"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            キャンセル
          </Button>
          <Button onClick={handleSendQuestion} color="primary">
            送信
          </Button>
        </DialogActions>
        <Typography
          variant="body2"
          color="error"
          style={{ padding: "16px", textAlign: "left" }}
        >
          質問内容によっては1/13中に返信できない可能性がございます。
          <br />
          〇〇が表示されない、〇〇が動かない場合、以下のケースが考えられます。
          <br />
         ・一度の大量アクセスによるサーバー負荷
          <br />
          ・ユーザ様の通信契約内容や電波環境による通信制限
          <br />
          「再読み込み」をして頂きますと状況が改善する可能性がございます。
          <br />
          改善しない場合、お時間をおいてアクセス頂きますようお願い申し上げます。
        </Typography>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default QuestionDialog;
