import React, { useEffect, useState } from "react";
import {
  Chat,
  Channel,
  MessageList,
  MessageInput,
  Window,
} from "stream-chat-react";
import { useStreamChatClient } from "../context/StreamChatProvider";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "stream-chat-react/dist/css/index.css";
import { TypingIndicator } from "./TypingIndicator";

import CustomMessageInput from "./CustomMessageInput"; // カスタムコンポーネントをインポート

import { logChatPromiseExecution } from "stream-chat";

const PrefectureChat = ({ groupId }) => {
  const chatClient = useStreamChatClient();
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [key, setKey] = useState(0); // キーをリセットしてMessageInputを再レンダリングさせるための状態

    // console.log("groupId => ",groupId)

  //   console.log("chatClient =>", chatClient);

  const logErrorToFirestore = async (error, additionalInfo = {}) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        const db = getFirestore();
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        const errorDetails = {
          errorMessage: error.message,
          errorStack: error.stack,
          ...additionalInfo,
          userId: user.uid,
          userInfo: userDoc.exists() ? userDoc.data() : {},
          userAgent: navigator.userAgent,
          url: window.location.href,
          date: new Date().toISOString(),
        };

        await setDoc(
          doc(db, "errors", `${user.uid}_${Date.now()}`),
          errorDetails
        );
      } catch (firestoreError) {
        console.error("Error logging to Firestore:", firestoreError);
      }
    } else {
      console.error(
        "No authenticated user found. Error not logged to Firestore."
      );
    }
  };

  useEffect(() => {
    const initChat = async () => {
      try {
        if (chatClient) {
          const auth = getAuth();
          const user = auth.currentUser;
          const userId = user ? user.uid : null;

          if (!userId) {
            console.error("User not authenticated");
            return;
          }

          const newChannel = chatClient.channel("messaging", groupId, {
            name: `Group Chat ${groupId}`,
            invite_only: false, // 誰でも参加できるチャネルにする
          });

          // チャネルが存在しない場合は作成する
          try {
            await newChannel.query(); // チャンネルが存在するか確認
          } catch (e) {
            if (e.response && e.response.status === 404) {
              console.log("Channel does not exist, creating new channel.");
              await newChannel.create(); // チャンネルが存在しない場合に作成
            } else {
              throw e; // 他のエラーは再度スロー
            }
          }

          // ユーザーをチャネルに追加
          await newChannel.addMembers([userId]);

          // チャネルをウォッチ
          await newChannel.watch();
          setChannel(newChannel);

          const response = await newChannel.query({
            messages: { limit: 20, sort: [{ created_at: -1 }] },
          });

          setMessages(response.messages.reverse());
        }
      } catch (error) {
        await logErrorToFirestore(error, { functionName: "initChat" });
      }
    };

    initChat();
  }, [chatClient, groupId]);

  // メッセージ送信のハンドラ
  const overrideSubmitHandler = async (message) => {
    // console.log("送信するメッセージ内容 =>", message);
    // console.log("添付ファイル =>", message.attachments); // 添付ファイルの内容を確認

    if (
      !message.text.trim() &&
      (!message.attachments || message.attachments.length === 0)
    ) {
      console.error("空のメッセージや添付ファイルのみは送信できません");
      return;
    }

    try {
      if (channel) {
        const sendMessagePromise = channel.sendMessage(message);
        logChatPromiseExecution(sendMessagePromise, "メッセージ送信");

        // MessageInputの再レンダリングをトリガーしてリセット
        setKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error("メッセージ送信失敗:", error);
    }
  };

  const actions = ["delete", "edit", "react"];

  if (!chatClient || !channel) return <div>Loading chat...</div>;

  return (
    <Chat client={chatClient}>
      <Channel channel={channel}>
        <Window>
          {/* <MessageList messageActions={actions}/> */}


          <MessageList
            hideNewMessageSeparator={true}
            showUnreadNotificationAlways={true}
            jumpToLatestMessage={() => {
              console.log("最新のメッセージにジャンプ");
              // 最新のメッセージにスクロール
            }}
          />

          <div className="pl-5 py-2 bg-white">
            <TypingIndicator />
          </div>

          {/* カスタムのMessageInputコンポーネントを利用 */}
          <CustomMessageInput overrideSubmitHandler={overrideSubmitHandler} />
        </Window>
      </Channel>
    </Chat>
  );
};

export default PrefectureChat;
