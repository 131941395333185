import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Slider,
} from "@mui/material";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

const SurveyDialog = ({ open, onClose, surveyId }) => {
  const [surveyAnswers, setSurveyAnswers] = useState({
    courseType: "",
    listenedTracks: [],
    listeningFrequency: "",
    listeningFrequencyOther: "",
    satisfaction: "",
    changes: "",
    finalActivityCount: "",
    missions: [],
    bestMissions: [],
    ddpActions: "",
    ddpAchieved: "",
    achievedDDP: "",
    ddpProgress: 50,
    ddpChanged: "",
    activityParticipation: "", // 新しいフィールド: 活動への参加
    friendsMade: "", // 新しいフィールド: 仲間ができたか
    continuation: "", // 新しいフィールド: 継続するか
    continuationExpectations: "", // 新しいフィールド: 継続時の期待
    activityParticipation: [], // 配列型に変更
  });

  const [isThankYouDialogOpen, setThankYouDialogOpen] = useState(false);

  const handleSurveyAnswerChange = (event) => {
    const { name, value, checked } = event.target;

    if (
      name === "listenedTracks" ||
      name === "missions" ||
      name === "bestMissions"
    ) {
      // 配列操作が必要な項目にのみ処理を限定
      const updatedArray = checked
        ? [...surveyAnswers[name], value]
        : surveyAnswers[name].filter((item) => item !== value);

      setSurveyAnswers((prev) => ({ ...prev, [name]: updatedArray }));
    } else {
      // 単一の値を扱う場合
      setSurveyAnswers((prev) => {
        const updatedAnswers = { ...prev, [name]: value };
        if (name === "courseType" && value === "音源のみ") {
          updatedAnswers.finalActivityCount = "";
          updatedAnswers.missions = [];
          updatedAnswers.bestMissions = [];
        }
        return updatedAnswers;
      });
    }
  };

  const handleSaveSurvey = async () => {
    const user = auth.currentUser;

    if (!user) {
      alert("ユーザーが認証されていません。");
      return;
    }

    if (
      !surveyAnswers.courseType ||
      !surveyAnswers.listeningFrequency ||
      !surveyAnswers.satisfaction
    ) {
      alert("すべての必須項目に回答してください。");
      return;
    }

    const surveyData = {
      createdAt: serverTimestamp(),
      surveyId,
      answers: { ...surveyAnswers },
      userId: user.uid,
    };

    try {
      const userFabRef = doc(db, "users", user.uid, "fab", surveyId);
      await setDoc(userFabRef, surveyData);

      const fabRef = collection(db, "fab");
      await addDoc(fabRef, surveyData);

      console.log(
        "アンケートが`/users/userId/fab`と`/fab`コレクションに保存されました"
      );

      setThankYouDialogOpen(true);
    } catch (error) {
      console.error("アンケートの保存に失敗しました", error);
      alert("アンケートの保存中にエラーが発生しました。");
    }
  };

  const handleThankYouDialogClose = () => {
    setThankYouDialogOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open && !isThankYouDialogOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>アンケート</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            以下の質問にお答えください。
          </Typography>

          <FormControl component="fieldset" fullWidth sx={{ mt: 2 }}>
            <Typography variant="subtitle2">
              どちらのコースでしたか？
            </Typography>
            <RadioGroup
              name="courseType"
              value={surveyAnswers.courseType}
              onChange={handleSurveyAnswerChange}
            >
              <FormControlLabel
                value="音源のみ"
                control={<Radio />}
                label="音源のみ"
              />
              <FormControlLabel
                value="グループ活動を通してDDPを叶え合う"
                control={<Radio />}
                label="グループ活動を通してDDPを叶え合う"
              />
            </RadioGroup>
          </FormControl>

          {surveyAnswers.courseType === "グループ活動を通してDDPを叶え合う" && (
            <>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="final-activity-count-label">
                  最終的な活動人数
                </InputLabel>
                <Select
                  labelId="final-activity-count-label"
                  name="finalActivityCount"
                  value={surveyAnswers.finalActivityCount}
                  onChange={handleSurveyAnswerChange}
                >
                  {Array.from({ length: 9 }, (_, i) => (
                    <MenuItem key={i + 1} value={`${i + 1}人`}>
                      {i + 1}人
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="missions-label">
                  グループで取り組めたミッションを教えてください
                </InputLabel>
                <Select
                  labelId="missions-label"
                  name="missions"
                  multiple
                  value={surveyAnswers.missions}
                  onChange={(event) =>
                    setSurveyAnswers((prev) => ({
                      ...prev,
                      missions:
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value,
                    }))
                  }
                  renderValue={(selected) => selected.join(", ")}
                >
                  {[
                    "共通点を見つけた",
                    "共通取り組み",
                    "DDP共通認識",
                    "DDP深掘り",
                    "ChatGPT",
                    "DDP確定",
                    "30理由本作成",
                    "ビジョンボード",
                    "予祝",
                    "行動変化",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox
                        checked={surveyAnswers.missions.includes(option)}
                      />
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="best-missions-label">
                  取り組んで良かったミッションを教えてください
                </InputLabel>
                <Select
                  labelId="best-missions-label"
                  name="bestMissions"
                  multiple
                  value={surveyAnswers.bestMissions}
                  onChange={(event) =>
                    setSurveyAnswers((prev) => ({
                      ...prev,
                      bestMissions:
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value,
                    }))
                  }
                  renderValue={(selected) => selected.join(", ")}
                >
                  {[
                    "共通点を見つけた",
                    "共通取り組み",
                    "DDP共通認識",
                    "DDP深掘り",
                    "ChatGPT",
                    "DDP確定",
                    "30理由本作成",
                    "ビジョンボード",
                    "予祝",
                    "行動変化",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox
                        checked={surveyAnswers.bestMissions.includes(option)}
                      />
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <Typography variant="subtitle2">
                  DDPを叶えるための行動をおこした・独自に取り組んだことがあれば教えてください。
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="ddpActions"
                  value={surveyAnswers.ddpActions}
                  onChange={handleSurveyAnswerChange}
                  placeholder="自由に記述してください"
                />
              </FormControl>

              <FormControl component="fieldset" fullWidth sx={{ mt: 2 }}>
                <Typography variant="subtitle2">DDPは叶いましたか？</Typography>
                <RadioGroup
                  name="ddpAchieved"
                  value={surveyAnswers.ddpAchieved}
                  onChange={handleSurveyAnswerChange}
                >
                  <FormControlLabel
                    value="はい"
                    control={<Radio />}
                    label="はい"
                  />
                  <FormControlLabel
                    value="いいえ"
                    control={<Radio />}
                    label="いいえ"
                  />
                </RadioGroup>
                {surveyAnswers.ddpAchieved === "はい" && (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    name="achievedDDP"
                    value={surveyAnswers.achievedDDP}
                    onChange={handleSurveyAnswerChange}
                    label="DDPがどう叶ったか、内容も含めて教えてください。"
                  />
                )}
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <Typography variant="subtitle2">
                  DDP達成度を教えてください。
                </Typography>
                <Slider
                  name="ddpProgress"
                  value={surveyAnswers.ddpProgress}
                  onChange={(event, value) =>
                    setSurveyAnswers((prev) => ({
                      ...prev,
                      ddpProgress: value,
                    }))
                  }
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={100}
                />
              </FormControl>

              <FormControl component="fieldset" fullWidth sx={{ mt: 2 }}>
                <Typography variant="subtitle2">
                  DDPがグループ活動参加前と冬至時点で変わりましたか？
                </Typography>
                <RadioGroup
                  name="ddpChanged"
                  value={surveyAnswers.ddpChanged}
                  onChange={handleSurveyAnswerChange}
                >
                  <FormControlLabel
                    value="はい"
                    control={<Radio />}
                    label="はい"
                  />
                  <FormControlLabel
                    value="いいえ"
                    control={<Radio />}
                    label="いいえ"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" fullWidth sx={{ mt: 2 }}>
                <Typography variant="subtitle2">
                  都道府県・地域のチャット・活動に参加しましたか？
                </Typography>
                <RadioGroup
                  name="activityParticipation"
                  value={surveyAnswers.activityParticipation}
                  onChange={handleSurveyAnswerChange}
                >
                  <FormControlLabel
                    value="グループのみ"
                    control={<Radio />}
                    label="グループのみ"
                  />
                  <FormControlLabel
                    value="都道府県"
                    control={<Radio />}
                    label="都道府県"
                  />
                  <FormControlLabel
                    value="地域"
                    control={<Radio />}
                    label="地域"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" fullWidth sx={{ mt: 2 }}>
                <Typography variant="subtitle2">
                  MMを卒業しても付き合える仲間ができましたか？
                </Typography>
                <RadioGroup
                  name="friendsMade"
                  value={surveyAnswers.friendsMade}
                  onChange={handleSurveyAnswerChange}
                >
                  <FormControlLabel
                    value="はい"
                    control={<Radio />}
                    label="はい"
                  />
                  <FormControlLabel
                    value="いいえ"
                    control={<Radio />}
                    label="いいえ"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" fullWidth sx={{ mt: 2 }}>
                <Typography variant="subtitle2">2期に継続しますか？</Typography>
                <RadioGroup
                  name="continuation"
                  value={surveyAnswers.continuation}
                  onChange={handleSurveyAnswerChange}
                >
                  <FormControlLabel
                    value="はい"
                    control={<Radio />}
                    label="はい"
                  />
                  <FormControlLabel
                    value="いいえ"
                    control={<Radio />}
                    label="いいえ"
                  />
                </RadioGroup>
                {surveyAnswers.continuation === "はい" && (
                  <TextField
                    fullWidth
                    margin="normal"
                    name="continuationExpectations"
                    value={surveyAnswers.continuationExpectations}
                    onChange={handleSurveyAnswerChange}
                    label="2期に期待することを教えてください"
                    multiline
                    rows={3}
                  />
                )}
              </FormControl>
            </>
          )}

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="listened-tracks-label">
              どの音源を聞きましたか？複数選択可
            </InputLabel>
            <Select
              labelId="listened-tracks-label"
              name="listenedTracks"
              multiple
              value={surveyAnswers.listenedTracks}
              onChange={(event) =>
                setSurveyAnswers((prev) => ({
                  ...prev,
                  listenedTracks:
                    typeof event.target.value === "string"
                      ? event.target.value.split(",")
                      : event.target.value,
                }))
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {[
                "レイの祈り（春分の音源）",
                "夏至の音源",
                "秋分の音源",
                "緊急音源（アメージンググレース）",
                "冬至の音源",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={surveyAnswers.listenedTracks.includes(option)}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="listening-frequency-label">
              音源をどのくらいの頻度で聞きましたか？
            </InputLabel>
            <Select
              labelId="listening-frequency-label"
              name="listeningFrequency"
              value={surveyAnswers.listeningFrequency}
              onChange={handleSurveyAnswerChange}
            >
              <MenuItem value="1日2回以上">1日2回以上</MenuItem>
              <MenuItem value="1日1回">1日1回</MenuItem>
              <MenuItem value="週に2～5回">週に2～5回</MenuItem>
              <MenuItem value="週に1回">週に1回</MenuItem>
              <MenuItem value="月に2，3回">月に2，3回</MenuItem>
              <MenuItem value="月に1回">月に1回</MenuItem>
              <MenuItem value="配信時のみ">配信時のみ</MenuItem>
              <MenuItem value="聞いてない">聞いてない</MenuItem>
              <MenuItem value="その他">その他</MenuItem>
            </Select>
            {surveyAnswers.listeningFrequency === "その他" && (
              <TextField
                fullWidth
                margin="normal"
                name="listeningFrequencyOther"
                value={surveyAnswers.listeningFrequencyOther}
                onChange={handleSurveyAnswerChange}
                label="その他の頻度を入力してください"
              />
            )}
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="satisfaction-label">
              音源の満足度を教えてください
            </InputLabel>
            <Select
              labelId="satisfaction-label"
              name="satisfaction"
              value={surveyAnswers.satisfaction}
              onChange={handleSurveyAnswerChange}
            >
              <MenuItem value="たいへん満足">たいへん満足</MenuItem>
              <MenuItem value="やや満足">やや満足</MenuItem>
              <MenuItem value="どちらでもない">どちらでもない</MenuItem>
              <MenuItem value="やや不満">やや不満</MenuItem>
              <MenuItem value="たいへん不満">たいへん不満</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <Typography variant="subtitle2">
              音源を聞いて変化したことがあれば教えてください。
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="changes"
              value={surveyAnswers.changes}
              onChange={handleSurveyAnswerChange}
              placeholder="自由に記述してください"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleSaveSurvey}
            color="primary"
            variant="contained"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isThankYouDialogOpen}
        onClose={handleThankYouDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>ありがとうございます</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            アンケートにご協力いただき、ありがとうございます。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThankYouDialogClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SurveyDialog;
