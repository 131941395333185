import React from "react";

// Vimeo IDを抽出する関数
const extractVimeoId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:video\/)?(\d+)(?:\/\S*)?/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

// Vimeoのハッシュ値を抽出する関数
const extractVimeoHash = (url) => {
  const regex = /vimeo\.com\/\d+\/([a-zA-Z0-9]+)/; // スラッシュで区切られたハッシュを抽出
  const match = url.match(regex);
  return match ? match[1] : null;
};

// YouTubeのIDを抽出する関数
const extractYouTubeId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=([^&]+)|youtu\.be\/([^?&]+))/;
  const match = url.match(regex);
  return match ? match[1] || match[2] : null;
};

// YouTubeの埋め込みURLに変換
const cleanYouTubeUrl = (url) => {
  const videoId = extractYouTubeId(url);
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
};

// 動画URLを埋め込みURLに変換する関数
const convertToEmbedUrl = (url) => {
  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    return cleanYouTubeUrl(url);
  } else if (url.includes("vimeo.com")) {
    const videoId = extractVimeoId(url);
    const vimeoHash = extractVimeoHash(url);
    return videoId
      ? `https://player.vimeo.com/video/${videoId}?h=${vimeoHash}&badge=0&autopause=0&player_id=0&app_id=58479&loop=1`
      : url;
  }
  return url;
};

// メディアを表示するコンポーネント
const MediaContent = ({ type, src }) => {
  const embedSrc = convertToEmbedUrl(src);

  if (src === "") return <></>;

  if (type === "video") {
    return (
      <div
        style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={embedSrc}
          title="Video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (type === "image") {
    return (
      <img
        src={src}
        alt="Post media"
        style={{ width: "100%", height: "auto" }}
      />
    );
  } else {
    return null; // メディアタイプが指定されていない場合は何も表示しない
  }
};

export default MediaContent;
