import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Snackbar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { CelebrationAnimation } from "./CelebrationAnimation";

const PrefectureDialog = ({ open, handleClose, onPrefectureUpdated }) => {
  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [selectedMunicipality, setSelectedMunicipality] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [error, setError] = useState("");


  const municipalities = [
  "札幌市",
  "江別市",
  "千歳市",
  "恵庭市",
  "北広島市",
  "石狩市",
  "当別町",
  "新篠津村",
  "岩見沢市",
  "夕張市",
  "美唄市",
  "芦別市",
  "赤平市",
  "三笠市",
  "滝川市",
  "砂川市",
  "歌志内市",
  "深川市",
  "南幌町",
  "奈井江町",
  "上砂川町",
  "由仁町",
  "長沼町",
  "栗山町",
  "月形町",
  "浦臼町",
  "新十津川町",
  "妹背牛町",
  "秩父別町",
  "雨竜町",
  "北竜町",
  "沼田町",
  "小樽市",
  "倶知安町",
  "寿都町",
  "黒松内町",
  "蘭越町",
  "ニセコ町",
  "喜茂別町",
  "京極町",
  "共和町",
  "岩内町",
  "積丹町",
  "古平町",
  "仁木町",
  "余市町",
  "島牧村",
  "真狩村",
  "留寿都村",
  "泊村",
  "神恵内村",
  "赤井川村",
  "室蘭市",
  "苫小牧市",
  "登別市",
  "伊達市",
  "豊浦町",
  "洞爺湖町",
  "壮瞥町",
  "白老町",
  "厚真町",
  "安平町",
  "むかわ町",
  "浦河町",
  "日高町",
  "平取町",
  "新冠町",
  "様似町",
  "えりも町",
  "新ひだか町",
  "函館市",
  "北斗市",
  "松前町",
  "福島町",
  "知内町",
  "木古内町",
  "七飯町",
  "鹿部町",
  "森町",
  "八雲町",
  "長万部町",
  "江差町",
  "上ノ国町",
  "厚沢部町",
  "乙部町",
  "奥尻町",
  "今金町",
  "せたな町",
  "旭川市",
  "富良野市",
  "士別市",
  "名寄市",
  "鷹栖町",
  "東神楽町",
  "当麻町",
  "比布町",
  "愛別町",
  "上川町",
  "東川町",
  "美瑛町",
  "上富良野町",
  "中富良野町",
  "南富良野町",
  "幌加内町",
  "和寒町",
  "剣淵町",
  "下川町",
  "美深町",
  "中川町",
  "占冠村",
  "音威子府村",
  "留萌市",
  "増毛町",
  "小平町",
  "苫前町",
  "羽幌町",
  "遠別町",
  "天塩町",
  "初山別村",
  "稚内市",
  "浜頓別町",
  "中頓別町",
  "枝幸町",
  "豊富町",
  "幌延町",
  "礼文町",
  "利尻町",
  "利尻富士町",
  "猿払村",
  "網走市",
  "北見市",
  "紋別市",
  "美幌町",
  "津別町",
  "大空町",
  "斜里町",
  "清里町",
  "小清水町",
  "訓子府町",
  "置戸町",
  "佐呂間町",
  "遠軽町",
  "湧別町",
  "滝上町",
  "興部町",
  "雄武町",
  "西興部村",
  "帯広市",
  "音更町",
  "士幌町",
  "上士幌町",
  "鹿追町",
  "新得町",
  "清水町",
  "芽室町",
  "大樹町",
  "広尾町",
  "幕別町",
  "池田町",
  "豊頃町",
  "本別町",
  "足寄町",
  "陸別町",
  "浦幌町",
  "中札内村",
  "更別村",
  "釧路市",
  "釧路町",
  "厚岸町",
  "浜中町",
  "標茶町",
  "弟子屈町",
  "白糠町",
  "鶴居村",
  "根室市",
  "別海町",
  "中標津町",
  "標津町",
  "羅臼町",
];

const regions = {
  "北海道・東北": [
    "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県",
  ],
  "関東": [
    "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県",
  ],
  "中部": [
    "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県",
  ],
  "近畿": [
    "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県",
  ],
  "中国": [
    "鳥取県", "島根県", "岡山県", "広島県", "山口県",
  ],
  "四国": [
    "徳島県", "香川県", "愛媛県", "高知県",
  ],
  "九州・沖縄": [
    "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県",
  ],
  "海外": [
    "海外（アメリカ）", "海外（その他）",
  ]
};


  useEffect(() => {
    if (!open) {
      setSelectedPrefecture("");
      setSelectedMunicipality("");
      setIsCheckboxChecked(false);
      setError("");
    }
  }, [open]);

  const handleUpdate = async () => {
    if (!selectedPrefecture || !isCheckboxChecked) return;

    try {
      const userDoc = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDoc, {
        prefecture: selectedPrefecture,
        municipality: selectedMunicipality || null, // 市区町村が未選択の場合はnull
        isRegisterPrefecture: true,
      });
      onPrefectureUpdated("居住県が登録されました！");
      handleEvent("prefectureRegistration");
      handleClose();
    } catch (error) {
      console.error("Failed to update prefecture:", error);
      setError("居住地の更新に失敗しました。もう一度お試しください。");
    }
  };

  const handleEvent = (eventType) => {
    if (eventType === "prefectureRegistration") {
      setCelebrationMessage("おめでとうございます！居住県が登録されました！");
      setIsOpen(true);
      setTimeout(() => setIsOpen(false), 3000);
    }
  };


  const renderMenuItems = () => {
    return Object.entries(regions).flatMap(([region, prefectures]) => [
      <ListSubheader key={region}>{region}</ListSubheader>,
      ...prefectures.map((prefecture) => (
        <MenuItem value={prefecture} key={prefecture}>
          {prefecture}
        </MenuItem>
      )),
    ]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>居住地を選択</DialogTitle>
      <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>都道府県</InputLabel>
          <Select
            value={selectedPrefecture}
            onChange={(event) => setSelectedPrefecture(event.target.value)}
            label="都道府県"
            renderValue={(selected) => selected}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {renderMenuItems()}
          </Select>
        </FormControl>

        {selectedPrefecture === "北海道" && (
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>市区町村</InputLabel>
            <Select
              value={selectedMunicipality}
              onChange={(event) => setSelectedMunicipality(event.target.value)}
              label="市区町村"
              renderValue={(selected) => selected}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {municipalities.map((municipality) => (
                <MenuItem value={municipality} key={municipality}>
                  {municipality}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <CelebrationAnimation
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          message={celebrationMessage}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              color="primary"
            />
          }
          label="一度決定した居住地は変更できません。必ずご確認ください。"
          style={{ marginTop: 20 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          閉じる
        </Button>
        <Button
          onClick={handleUpdate}
          color="primary"
          variant="contained"
          disabled={!selectedPrefecture || !isCheckboxChecked || (selectedPrefecture === "北海道" && !selectedMunicipality)}
        >
          更新
        </Button>
      </DialogActions>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}
        message={error}
      />
    </Dialog>
  );
};

export default PrefectureDialog;
