import React, { useEffect, useState } from "react";
import { db, auth } from "../api/firebase";
import {
  collection,
  onSnapshot,
  getDoc,
  doc,
  updateDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import {
  Box,
  List,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  TextField,
  Avatar,
  Snackbar,
  CircularProgress,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ja";
import { useNavigate } from "react-router-dom";

dayjs.extend(relativeTime);
dayjs.locale("ja");

const SpecificCategoryRequests = () => {
  const [requests, setRequests] = useState([]);
  const [responses, setResponses] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      const userDoc = doc(db, "users", auth.currentUser.uid);
      const userSnap = await getDoc(userDoc);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setIsAdmin(userData.isAdmin);
        if (!userData.isAdmin) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    };

    checkAdmin();
  }, [navigate]);

  useEffect(() => {
    const q = query(
      collection(db, "support"),
      where("category", "==", "2025年1月13日、DDP添削会について"),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const requestsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequests(requestsData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching specific category requests: ", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const toggleResolvedStatus = async (id, currentStatus) => {
    const requestDoc = doc(db, "support", id);
    await updateDoc(requestDoc, { isResolved: !currentStatus });
    setRequests((prevRequests) =>
      prevRequests.map((request) =>
        request.id === id ? { ...request, isResolved: !currentStatus } : request
      )
    );
  };

  const handleResponseChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  const addResponse = async (id) => {
    const newResponse = {
      text: responses[id],
      responderId: auth.currentUser.uid,
      respondedAt: dayjs().toISOString(),
    };
    const requestDoc = doc(db, "support", id);
    const request = requests.find((req) => req.id === id);

    const updatedResponses = request.responses
      ? [newResponse, ...request.responses]
      : [newResponse];
    await updateDoc(requestDoc, { responses: updatedResponses });

    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === id ? { ...req, responses: updatedResponses } : req
      )
    );
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: "",
    }));
    setSnackbarMessage("対応を追加しました");
    setSnackbarOpen(true);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        2025年1月13日、DDP添削会についてのお問い合わせ
      </Typography>
      <List>
        {requests.map((request) => (
          <Accordion key={request.id} sx={{ mb: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${request.id}-content`}
              id={`panel${request.id}-header`}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                <Avatar
                  alt={request.name}
                  src={request.avatar}
                  sx={{ mr: 1 }}
                />
                <Typography variant="body1">{request.name}</Typography>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
              >
                <Chip
                  label={request.isResolved ? "解決済み" : "未解決"}
                  color={request.isResolved ? "primary" : "secondary"}
                  sx={{ ml: 2 }}
                />
                <Typography variant="body2" sx={{ ml: 2 }}>
                  {dayjs(request.createdAt.toDate()).fromNow()} (
                  {dayjs(request.createdAt.toDate()).format("YYYY-MM-DD HH:mm:ss")}
                  )
                </Typography>
              </Box>
              <Button
                variant="contained"
                color={request.isResolved ? "secondary" : "primary"}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleResolvedStatus(request.id, request.isResolved);
                }}
                sx={{ alignSelf: "flex-start", ml: "auto" }}
              >
                {request.isResolved ? "未解決にする" : "解決にする"}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                {request.message}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>ログインメール:</strong>{" "}
                <Link href={`mailto:${request.loginEmail}`} target="_blank">
                  {request.loginEmail || "N/A"}
                </Link>
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>ユーザーID:</strong> {request.userId || "N/A"}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>返信先メール:</strong>{" "}
                <Link href={`mailto:${request.replyEmail}`} target="_blank">
                  {request.replyEmail || "N/A"}
                </Link>
              </Typography>
              <TextField
                fullWidth
                label="対応を入力"
                variant="outlined"
                value={responses[request.id] || ""}
                onChange={(e) =>
                  handleResponseChange(request.id, e.target.value)
                }
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addResponse(request.id)}
                  disabled={
                    !responses[request.id] || responses[request.id].trim() === ""
                  }
                  sx={{ alignSelf: "flex-start" }}
                >
                  対応を追加
                </Button>
              </Box>
              {request.responses && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">対応履歴</Typography>
                  {request.responses.map((response, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <Avatar
                          alt="Responder"
                          src={response.responderId}
                          sx={{ width: 24, height: 24, mr: 1 }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          {dayjs(response.respondedAt).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}{" "}
                          - {response.text}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default SpecificCategoryRequests;
