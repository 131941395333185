import React, { useState, useEffect } from "react";
import { db, auth } from "../api/firebase"; // Adjust the import path as necessary
import {
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  serverTimestamp,
  addDoc,
  deleteField,
  collection,
} from "firebase/firestore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  ThemeProvider,
  createTheme,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import QuestionDialog from "./QuestionDialog";

// テーマの定義
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // プライマリカラー
    },
    secondary: {
      main: "#dc004e", // セカンダリカラー
    },
    error: {
      main: "#f44336", // エラーカラー
    },
  },
});

// URLを定数として管理
const YOUTUBE_LIVE_URL = "https://youtube.com/live/3tyibtlbDBE?feature=share";
const ZOOM_PANELIST_URL =
  "https://us02web.zoom.us/j/84029286922?pwd=ueQr79vzev5jb6RbJlnkLkSbXhMhmS.1";

// Webinar IDを定数として管理
const WEBINAR_ID = "20250113";

// 特定のグループIDリスト
const excludedGroupIds = [
  "2OlbuQaScvEwyJE7GfzD",
  "bKnWO1FRfsbyJIlyaiUI",
  "p5BOSCtW941AO0Rjyxi6",
  "zURFW3OsWMQiEMrxMpOn",
  "AX97qqd4gPOYfHPPzHn2",
  "UWmkFqTOQ87o6xnIUyrd",
  "cYC7Ourn9nVKWJrDVi4f",
  "o6bSkJ6hulakznNDu3uK",
  "4L0zG7yRjuB5HXifZbN2",
  "kAY68XxHAcBTV6lMVpGA",
  "MLgQe0JlfGYYikwOQvT6",
  "rIsA8hFDH2cL45ElttKb",
  "6evs8euN7dmvvLkCppEt",
  "F2cdZV2biR2GNdswplB8",
  "LVsMHGbYRa64SBDgLFSn",
  "aPVzjO0iB4Dg6bAgCg9O",
  "bp9YFgXuRa6gg6GRuc1A",
  "iMUEQ4wnVcQq5EbI25eb",
  "w7ApPCJa7Dh0SuvUvEjS",
  "r9j6HmLhmw5wbflIlgjy",
  "W6VgEaibydbWByRdIOAG",
  "IwCduTR4BAvsQwGllRsg",
  "ymhO4I4SwJjImil672AT",
  "WuXmprRLsOpsmGvCwQIT",
  // "RiOdopHgxu6n1iv4G4uQ"
];

const Webinar = () => {
  const [panelistUrl, setPanelistUrl] = useState(ZOOM_PANELIST_URL);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [openedBy, setOpenedBy] = useState("");
  const [openedByID, setOpenedByID] = useState("");
  const [openedAt, setOpenedAt] = useState(null);
  const [userName, setUserName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const { id } = useParams();
  const userId = auth.currentUser?.uid;
  const webinarId = WEBINAR_ID;

  // usersコレクションからユーザー情報を取得
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userId) {
        try {
          const userDoc = await getDoc(doc(db, "users", userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserName(userData.name || "匿名ユーザー");
            setIsAdmin(userData.isAdmin || false);
          } else {
            console.error("ユーザー情報が見つかりません");
          }
        } catch (error) {
          console.error("ユーザー情報の取得に失敗しました", error);
        }
      }
    };

    fetchUserInfo();
  }, [userId]);

  // Firestoreのリアルタイムリスナーでドキュメントの変更を監視
  useEffect(() => {
    if (!id) return;

    // ドキュメント参照を "groups/{id}/webinar/{WEBINAR_ID}" に変更
    const webinarDocRef = doc(db, "groups", id, "webinar", webinarId);

    const unsubscribe = onSnapshot(webinarDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setIsOpened(data.isOpened || false);
        setOpenedBy(data.openedBy || "");
        setOpenedByID(data.openedByID || "");
        setOpenedAt(data.openedAt?.toDate() || null);

        // 他のユーザーがURLを開いたらダイアログを閉じる
        if (data.isOpened && isDialogOpen) {
          setIsDialogOpen(false);
        }
      } else {
        // ドキュメントが存在しない場合は初期化
        setIsOpened(false);
        setOpenedBy("");
        setOpenedByID("");
        setOpenedAt(null);
      }
    });

    return () => unsubscribe();
  }, [id, webinarId, isDialogOpen]);

  // フィールドを削除する関数
  const handleDeleteFields = async () => {
    if (id) {
      // ドキュメント参照を "groups/{id}/webinar/{WEBINAR_ID}" に変更
      const webinarDocRef = doc(db, "groups", id, "webinar", webinarId);
      try {
        await setDoc(
          webinarDocRef,
          {
            openedAt: deleteField(),
            openedBy: deleteField(),
            openedByID: deleteField(),
            isOpened: deleteField(),
          },
          { merge: true } // merge: true を追加
        );
        console.log("フィールドが正常に削除されました");
      } catch (error) {
        console.error("フィールド削除中にエラーが発生しました:", error);
      }
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleOpenUrl = async () => {
    if (userId) {
      // ドキュメント参照を "groups/{id}/webinar/{WEBINAR_ID}" に変更
      const webinarDocRef = doc(db, "groups", id, "webinar", webinarId);

      try {
        // Firestoreにグループの情報をセット（存在しない場合は作成）
        await setDoc(
          webinarDocRef,
          {
            isOpened: true,
            openedBy: userName, // usersコレクションから取得したユーザー名
            openedByID: userId, // uidを保存
            openedAt: serverTimestamp(),
          },
          { merge: true } // merge: true を追加
        );

        // 特定のグループIDに含まれていない場合にwebinarコレクションとwebinar20241120コレクションにデータを追加
        if (!excludedGroupIds.includes(id)) {
          const webinarData = {
            openedBy: userName || "Unknown",
            openedByID: userId || "Unknown",
            openedAt: serverTimestamp(),
            groupID: id,
            webinar: webinarId,
          };

          // webinarコレクションに追加
          try {
            await addDoc(collection(db, "webinar"), webinarData);
          } catch (error) {
            console.error("Error adding document to webinar collection:", error);
          }

          // webinar20241120コレクションに追加
          try {
            await addDoc(collection(db, `webinar${webinarId}`), webinarData);
          } catch (error) {
            console.error(`Error adding document to webinar${webinarId} collection:`, error);
          }
        }

        // URLを開く
        window.open(panelistUrl, "_blank");
        setIsDialogOpen(false);
        setIsChecked(false);
      } catch (error) {
        console.error("Error updating webinar document:", error);
      }
    }
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setIsChecked(false);
  };

  // グループIDが除外リストに含まれているか確認
  const isExcludedGroup = excludedGroupIds.includes(id);

  return (
    <>
      {!isExcludedGroup ? (
        <ThemeProvider theme={theme}>
          <div className="p-4">
            {/* YouTube Live セクション */}
            <Card className="mb-4">
              <CardContent>
                <Typography variant="subtitle1" className="mb-2">
                  YouTube LiveのURL
                </Typography>
                <Typography variant="body2" className="mb-4">
                  下記URLでYouTube Liveに参加できます。
                  <br />
                  このYouTube Liveはマスターマインドメンバーズ全員が参加できます。
                </Typography>
                <Typography variant="body1" color="primary" className="mb-4">
                  <a
                    href={YOUTUBE_LIVE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {YOUTUBE_LIVE_URL}
                  </a>
                </Typography>
              </CardContent>
            </Card>
            {/* Zoom セクション */}
            <Card>
              <CardContent>
                <Typography variant="subtitle1" className="mb-2">
                  ZoomミーティングのURL
                </Typography>
                {isOpened ? (
                  <Typography variant="body2" color="error" className="mb-4">
                    {openedBy}がZoomミーティングのURLを取得しました。
                    <br />
                    {openedAt && `取得日時: ${openedAt.toLocaleString()}`}
                    {openedByID === userId && (
                      <>
                        <br />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenUrl}
                          className="mt-2"
                        >
                          パネリストとして参加
                        </Button>

                        <div>パスワード：2024</div>
                      </>
                    )}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      variant="body2"
                      color="error"
                      className="mb-4"
                    >
                      下記URLはマスターマインドメンバーズの1グループにつき1つ配布されるものです。
                      <br />
                      グループの誰かが開いてしまうと、二度とURLが発行されません。
                      <br />
                      誰がパネリストとして参加するかをグループ内で決定後、パネリストの方のみ開いてください。
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenDialog}
                    >
                      ZoomミーティングのパネリストURLを開く
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>

            {/* 管理者専用ボタン */}
            {isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteFields}
                className="mt-4"
              >
                フィールドを削除
              </Button>
            )}

            {/* その他の表示内容 */}
            <Dialog open={isDialogOpen} onClose={handleCancel}>
              <DialogTitle>注意事項</DialogTitle>
              <DialogContent>
                <Typography variant="body2" color="error" gutterBottom>
                  下記URLは、最初に開いた人以降開けません。誰がパネリストとして参加するかグループ内でお話し合いをよろしくお願いします。
                </Typography>
                <Typography variant="body2" gutterBottom>
                  ・1グループにつきお一人のアカウントが使えます。
                </Typography>
                <Typography variant="body2" gutterBottom>
                  ・仮にお二人以上のアカウントが入室されますと、強制退去される可能性があります。
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      name="confirm"
                      color="primary"
                    />
                  }
                  label="上記内容を確認しました。"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="inherit">
                  キャンセル
                </Button>
                <Button
                  onClick={handleOpenUrl}
                  color="primary"
                  disabled={!isChecked}
                >
                  開く
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </ThemeProvider>
      ) : (
        <>
          <Card className="mb-4">
            <CardContent>
              <Typography variant="subtitle1" className="mb-2">
                YouTube LiveのURL
              </Typography>
              <Typography variant="body2" className="mb-4">
                下記URLでYouTube Liveに参加できます。
                <br />
              </Typography>
              <Typography variant="body1" color="primary" className="mb-4">
                <a
                  href={YOUTUBE_LIVE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {YOUTUBE_LIVE_URL}
                </a>
              </Typography>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              // excludedGroupIdsに含まれる場合、以下の処理を実行
              if (isExcludedGroup) {
                if (userId) {
                  const webinarDocRef = doc(db, "groups", id, "webinar", webinarId);

                  try {
                    // Firestoreにグループの情報をセット（存在しない場合は作成）
                    await setDoc(
                      webinarDocRef,
                      {
                        isOpened: true,
                        openedBy: userName, // usersコレクションから取得したユーザー名
                        openedByID: userId, // uidを保存
                        openedAt: serverTimestamp(),
                      },
                      { merge: true } // merge: true を追加
                    );

                    const webinarData = {
                      openedBy: userName || "Unknown",
                      openedByID: userId || "Unknown",
                      openedAt: serverTimestamp(),
                      groupID: id,
                      webinar: webinarId,
                    };

                    // webinarコレクションに追加
                    try {
                      await addDoc(collection(db, "webinar"), webinarData);
                    } catch (error) {
                      console.error("Error adding document to webinar collection:", error);
                    }

                    // webinar20241120コレクションに追加
                    try {
                      await addDoc(collection(db, `webinar${webinarId}`), webinarData);
                    } catch (error) {
                      console.error(`Error adding document to webinar${webinarId} collection:`, error);
                    }

                    // URLを開く
                    window.open(panelistUrl, "_blank");
                  } catch (error) {
                    console.error("Error updating webinar document:", error);
                  }
                }
              } else {
                // excludedGroupIdsに含まれない場合、通常の処理を実行
                handleOpenUrl();
              }
            }}
            className="mt-2"
          >
            パネリストとして参加
          </Button>

          <div>パスワード：2024</div>
        </>
      )}


        {/* <QuestionDialog /> */}

    </>
  );
};

export default Webinar;
