import React, { memo, useState, useEffect } from "react";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Divider,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import LoaderName from "./LoaderName";
import EditNameDialog from "./EditNameDialog";
import BirthDayEditDialog from "./BirthDayEditDialog";
import { CelebrationAnimation } from "./CelebrationAnimation";
import PrefectureDialog from "./PrefectureDialog";
import dayjs from "dayjs";

const ProfileCard = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [editBirthDayOpen, setEditBirthDayOpen] = useState(false);
  const [prefectureDialogOpen, setPrefectureDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState("");
  const [purpose, setPurpose] = useState("");
  const [canChangePurpose, setCanChangePurpose] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timerVisible, setTimerVisible] = useState(true);
  const [useDefaultEmail, setUseDefaultEmail] = useState(true);
  const [customEmail, setCustomEmail] = useState("");

  const changeDeadline = dayjs("2024-07-14T23:59:00").valueOf();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "users", auth.currentUser.uid),
      (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setUser(userData);
          setPurpose(userData.purpose || "");
          const defaultEmail = userData.email;
          const broadcastReplyEmail = userData.broadcastReplyEmail || defaultEmail;
          setUseDefaultEmail(!userData.broadcastReplyEmail || userData.broadcastReplyEmail === defaultEmail);
          setCustomEmail(broadcastReplyEmail);
          const now = dayjs().valueOf();
          if (now > changeDeadline) {
            setCanChangePurpose(false);
            setTimerVisible(false);
          }
          setTimeLeft(changeDeadline - now);
        } else {
          console.error("No such document!");
          setError("Document not found");
        }
      }
    );

    const intervalId = setInterval(() => {
      const now = dayjs().valueOf();
      const diff = changeDeadline - now;
      setTimeLeft(diff);
      if (diff <= 0) {
        setCanChangePurpose(false);
        setTimerVisible(false);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      unsubscribe();
      clearInterval(intervalId);
    };
  }, [changeDeadline]);

  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${days}日 ${hours}時間 ${minutes}分 ${secs}秒`;
  };

  const handleEditNameClose = () => {
    setEditNameOpen(false);
  };

  const handleEditNameSave = (name) => {
    updateDoc(doc(db, "users", auth.currentUser.uid), { name })
      .then(() => {
        setSnackbarMessage("名前を更新しました。");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error updating user name", error);
        setError(error.message);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleEditBirthDayClose = () => {
    setEditBirthDayOpen(false);
  };

  const handleEditBirthDaySave = (date) => {
    const { year, month, day } = date;
    updateDoc(doc(db, "users", auth.currentUser.uid), {
      birthyear: year,
      birthmonth: month,
      birthday: day,
    })
      .then(() => {
        setSnackbarMessage("生年月日を更新しました。");
        setSnackbarOpen(true);
        setEditBirthDayOpen(false);
      })
      .catch((error) => {
        console.error("Error updating birthdate", error);
        setError(error.message);
      });
  };

  const handlePrefectureUpdated = (message) => {
    setCelebrationMessage(message);
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 10000);
  };

  const handlePurposeChange = (event) => {
    const newPurpose = event.target.value;
    setPurpose(newPurpose);
    updateDoc(doc(db, "users", auth.currentUser.uid), { purpose: newPurpose })
      .then(() => {
        setSnackbarMessage("利用目的を更新しました。");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error updating purpose", error);
        setError(error.message);
      });
  };

  const handleChange = async (e) => {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      [e.target.name]: e.target.value,
    });
  };

  const handleEmailPreferenceChange = (event) => {
    setUseDefaultEmail(event.target.value === "default");
  };

  const handleCustomEmailSave = () => {
    const emailToSave = useDefaultEmail ? user.email : customEmail;
    updateDoc(doc(db, "users", auth.currentUser.uid), {
      broadcastReplyEmail: emailToSave,
    })
      .then(() => {
        setSnackbarMessage("一斉メールの設定を更新しました。");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error updating broadcast reply email", error);
        setError(error.message);
      });
  };

  const prefectures = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];

  return (
    <Box sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          基本情報
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">名前</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {user?.name || <LoaderName />}
                  </Typography>
                  <IconButton onClick={() => setEditNameOpen(true)}>
                    <EditIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">誕生日</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {user && user.birthyear && user.birthmonth && user.birthday
                      ? `${user.birthyear}/${user.birthmonth}/${user.birthday}`
                      : ""}
                  </Typography>
                  <IconButton onClick={() => setEditBirthDayOpen(true)}>
                    <EditIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">性別</Typography>
                <RadioGroup
                  row
                  aria-labelledby="gender-radio-group"
                  name="sextype"
                  value={user?.sextype || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="男性"
                    control={<Radio />}
                    label="男性"
                  />
                  <FormControlLabel
                    value="女性"
                    control={<Radio />}
                    label="女性"
                  />
                  <FormControlLabel
                    value="回答しない"
                    control={<Radio />}
                    label="回答しない"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">血液型</Typography>
                <RadioGroup
                  row
                  aria-labelledby="bloodtype-radio-group"
                  name="bloodtype"
                  value={user?.bloodtype || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="A" control={<Radio />} label="A型" />
                  <FormControlLabel value="B" control={<Radio />} label="B型" />
                  <FormControlLabel value="O" control={<Radio />} label="O型" />
                  <FormControlLabel
                    value="AB"
                    control={<Radio />}
                    label="AB型"
                  />
                  <FormControlLabel
                    value="わからない"
                    control={<Radio />}
                    label="わからない"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Divider sx={{ marginY: 2 }} />

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">居住地</Typography>
                {user?.isRegisterPrefecture ? (
                  <Box>
                    <Typography
                      variant="body1"
                      className="text-2xl font-bold text-blue-500"
                    >
                      {user?.prefecture}
                    </Typography>
                    <Typography variant="caption">
                      ※一度決定した居住地は変更できません。
                    </Typography>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => setPrefectureDialogOpen(true)}
                  >
                    居住地を編集
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            {/* <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">利用目的</Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  利用目的は2024/7/14
                  23:59までは変更が可能です。それ以降は変更できなくなります。
                </Typography>
                {timerVisible && (
                  <Box mt={2} mb={2}>
                    <Divider />
                    <Typography variant="body1" align="center" mt={1}>
                      残り時間: {formatTime(timeLeft)}
                    </Typography>
                    <Divider />
                  </Box>
                )}
                <RadioGroup
                  name="purpose"
                  value={purpose}
                  onChange={handlePurposeChange}
                  disabled={!canChangePurpose}
                >
                  <FormControlLabel
                    value="音源のダウンロードのみ"
                    control={<Radio />}
                    label="音源のダウンロードのみ"
                    disabled={!canChangePurpose}
                  />
                  <FormControlLabel
                    value="音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"
                    control={<Radio />}
                    label="音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"
                    disabled={!canChangePurpose}
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Divider sx={{ marginY: 2 }} /> */}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">認証状態</Typography>
                {user?.isVerified ? (
                  <Box display="flex" alignItems="center" color="green">
                    <VerifiedIcon />
                    <Typography variant="body1" sx={{ marginLeft: 1 }}>
                      認証済
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body1">認証中です。</Typography>
                  </Box>
                )}
                <Typography variant="body1">
                  登録時の名前: {user?.registrationName || "未登録"}
                </Typography>
                <Typography variant="body1">
                  登録時の電話番号: {user?.registrationPhone || "未登録"}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ marginY: 2 }} />

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">一斉メール設定</Typography>
                <Box mt={2}>
                  <Typography variant="body1">
                    現在の一斉メール受信アドレス：{user?.broadcastReplyEmail}
                    {/* 一斉メール受信アドレス：{useDefaultEmail ? user?.email : customEmail} */}
                  </Typography>
                </Box>

                <RadioGroup
                  row
                  name="emailPreference"
                  value={useDefaultEmail ? "default" : "custom"}
                  onChange={handleEmailPreferenceChange}
                >
                  <FormControlLabel
                    value="default"
                    control={<Radio />}
                    label={`ログインメール (${user?.email}) を使用`}
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="ご要望のメールアドレスを使用"
                  />
                </RadioGroup>
                {!useDefaultEmail && (
                  <TextField
                    fullWidth
                    label="メールアドレス"
                    variant="outlined"
                    value={customEmail}
                    onChange={(e) => setCustomEmail(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCustomEmailSave}
                >
                  保存
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>

      <CelebrationAnimation
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        message={celebrationMessage}
      />
      <PrefectureDialog
        open={prefectureDialogOpen}
        handleClose={() => setPrefectureDialogOpen(false)}
        onPrefectureUpdated={handlePrefectureUpdated}
      />
      <EditNameDialog
        open={editNameOpen}
        handleClose={handleEditNameClose}
        handleSave={handleEditNameSave}
        name={user?.name}
      />
      <BirthDayEditDialog
        open={editBirthDayOpen}
        handleClose={handleEditBirthDayClose}
        handleSave={handleEditBirthDaySave}
        currentBirthDate={`${user?.birthyear}-${user?.birthmonth}-${user?.birthday}`}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default memo(ProfileCard);
