import React, { useEffect, useState } from "react";
import { db, auth } from "../api/firebase";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  Timestamp,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  Box,
  List,
  Tab,
  Tabs,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  TextField,
  Avatar,
  MenuItem,
  Select,
  Snackbar,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ja";
import { useNavigate } from "react-router-dom";

dayjs.extend(relativeTime);
dayjs.locale("ja");

const prefectures = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

const ContactRequests = () => {
  const [requests, setRequests] = useState([]);
  const [users, setUsers] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [responses, setResponses] = useState({});
  const [prefecture, setPrefecture] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editingResponse, setEditingResponse] = useState(null);
  const [deletingResponse, setDeletingResponse] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
        if (!userData.isAdmin) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    };

    checkAdmin();
  }, [navigate]);

  useEffect(() => {
    const q = query(
      collection(db, "support"),
      orderBy("createdAt", "desc"),
      limit(1000)
    );

    const unsubscribeRequests = onSnapshot(
      q,
      (snapshot) => {
        const requestsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequests(requestsData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching support requests: ", error);
      }
    );

    return () => {
      unsubscribeRequests();
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleResolvedStatus = async (id, currentStatus) => {
    const requestDoc = doc(db, "support", id);
    await updateDoc(requestDoc, { isResolved: !currentStatus });
    setRequests((prevRequests) =>
      prevRequests.map((request) =>
        request.id === id ? { ...request, isResolved: !currentStatus } : request
      )
    );
  };

  const handleResponseChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  const addResponse = async (id) => {
    const newResponse = {
      text: responses[id],
      responderId: auth.currentUser.uid,
      respondedAt: Timestamp.now(),
    };
    const requestDoc = doc(db, "support", id);
    const request = requests.find((req) => req.id === id);

    const updatedResponses = request.responses
      ? [newResponse, ...request.responses]
      : [newResponse];
    await updateDoc(requestDoc, { responses: updatedResponses });

    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === id ? { ...req, responses: updatedResponses } : req
      )
    );
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: "",
    }));
  };

  const updatePrefecture = async (
    requestId,
    userId,
    userName,
    currentPrefecture
  ) => {
    const userDoc = doc(db, "users", userId);
    await updateDoc(userDoc, { prefecture });

    const response = {
      text: `都道府県を「${currentPrefecture}」から「${prefecture}」に更新`,
      responderId: auth.currentUser.uid,
      respondedAt: Timestamp.now(),
    };
    const requestDoc = doc(db, "support", requestId);
    const request = requests.find((req) => req.id === requestId);
    const updatedResponses = request.responses
      ? [response, ...request.responses]
      : [response];
    await updateDoc(requestDoc, { responses: updatedResponses });

    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === requestId ? { ...req, responses: updatedResponses } : req
      )
    );

    setSnackbarMessage(`${userName}の都道府県を更新しました`);
    setSnackbarOpen(true);
  };

  const fetchUser = async (userId) => {
    const userDoc = doc(db, "users", userId);
    const userSnapshot = await getDoc(userDoc);
    if (userSnapshot.exists()) {
      return userSnapshot.data();
    } else {
      console.error("User not found: ", userId);
      return null;
    }
  };

  const handleEditDialogOpen = (response) => {
    setEditingResponse(response);
    setEditingText(response.text);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditingResponse(null);
    setEditingText("");
  };

  const handleEditSave = async () => {
    const requestDoc = doc(db, "support", editingResponse.requestId);
    const request = requests.find(
      (req) => req.id === editingResponse.requestId
    );
    const updatedResponses = request.responses.map((res) =>
      res.respondedAt.isEqual(editingResponse.respondedAt)
        ? { ...res, text: editingText }
        : res
    );
    await updateDoc(requestDoc, { responses: updatedResponses });

    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === editingResponse.requestId
          ? { ...req, responses: updatedResponses }
          : req
      )
    );

    setSnackbarMessage("対応履歴編集完了しました");
    setSnackbarOpen(true);
    handleEditDialogClose();
  };

  const handleDeleteDialogOpen = (response) => {
    setDeletingResponse(response);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDeletingResponse(null);
  };

  const handleDeleteConfirm = async () => {
    const requestDoc = doc(db, "support", deletingResponse.requestId);
    const request = requests.find(
      (req) => req.id === deletingResponse.requestId
    );
    const updatedResponses = request.responses.filter(
      (res) => !res.respondedAt.isEqual(deletingResponse.respondedAt)
    );
    await updateDoc(requestDoc, { responses: updatedResponses });

    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === deletingResponse.requestId
          ? { ...req, responses: updatedResponses }
          : req
      )
    );

    setSnackbarMessage("対応履歴消去完了しました");
    setSnackbarOpen(true);
    handleDeleteDialogClose();
  };

  const handleAccordionChange = async (id) => {
    if (!users[id]) {
      const user = await fetchUser(id);
      if (user) {
        setUsers((prevUsers) => ({
          ...prevUsers,
          [id]: user,
        }));
      }
    }
  };

  const resolvedRequests = requests.filter(
    (request) => request.isResolved === true
  );
  const unresolvedRequests = requests.filter(
    (request) => request.isResolved === false
  );

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        ユーザーからのお問い合わせ
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="問い合わせの状態"
      >
        <Tab label="未解決" />
        <Tab label="解決" />
      </Tabs>
      {tabValue === 0 && (
        <List>
          {unresolvedRequests.map((request) => (
            <Accordion
              key={request.id}
              sx={{ mb: 2 }}
              onChange={() => handleAccordionChange(request.userId)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${request.id}-content`}
                id={`panel${request.id}-header`}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                  <Avatar
                    alt={request.name}
                    src={request.avatar || users[request.userId]?.avatar}
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="body1">
                    {request.name || users[request.userId]?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <Typography variant="h6">{request.category}</Typography>
                  <Chip label="未解決" color="secondary" sx={{ ml: 2 }} />
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {dayjs(request.createdAt.toDate()).fromNow()} (
                    {dayjs(request.createdAt.toDate()).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                    )
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleResolvedStatus(request.id, request.isResolved);
                  }}
                  sx={{ alignSelf: "flex-start", ml: "auto" }}
                >
                  解決にする
                </Button>
              </AccordionSummary>

              <AccordionDetails>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 2 }}
                >
                  {request.message.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
                <div className="flex space-x-2">
                  <div>userId:</div>
                  <div>{request?.userId}</div>
                </div>
                {request.groupId && (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      グループID:
                    </Typography>
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(`/group/${request.groupId}`, "_blank");
                      }}
                      variant="body2"
                      sx={{ cursor: "pointer" }}
                    >
                      {request.groupId}
                    </Link>
                  </Box>
                )}

                {request.loginEmail ? (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      ログインメール:
                    </Typography>
                    <Link href={`mailto:${request.loginEmail}`} variant="body2">
                      {request.loginEmail}
                    </Link>
                  </Box>
                ) : (
                  request.userId && (
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Typography variant="body2" sx={{ mr: 1 }}>
                        ログインメール:
                      </Typography>
                      <Link
                        href={`mailto:${users[request.userId]?.email}`}
                        variant="body2"
                      >
                        {users[request.userId]?.email}
                      </Link>
                    </Box>
                  )
                )}
                {request.replyEmail && (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      返信先メール:
                    </Typography>
                    <Link href={`mailto:${request.replyEmail}`} variant="body2">
                      {request.replyEmail}
                    </Link>
                  </Box>
                )}
                {request.category === "居住地について" && request.userId && (
                  <Box sx={{ mb: 2 }}>
                    {users[request.userId]?.prefecture ? (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          現在の都道府県: {users[request.userId]?.prefecture}
                        </Typography>
                        <Select
                          fullWidth
                          value={prefecture}
                          onChange={(e) => setPrefecture(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            都道府県を選択
                          </MenuItem>
                          {prefectures.map((pref) => (
                            <MenuItem key={pref} value={pref}>
                              {pref}
                            </MenuItem>
                          ))}
                        </Select>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            updatePrefecture(
                              request.id,
                              request.userId,
                              users[request.userId]?.name,
                              users[request.userId]?.prefecture
                            )
                          }
                          sx={{ mt: 1 }}
                        >
                          更新
                        </Button>
                      </>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        居住地が未設定です
                      </Typography>
                    )}
                  </Box>
                )}
                <TextField
                  fullWidth
                  label="対応を入力"
                  variant="outlined"
                  value={responses[request.id] || ""}
                  onChange={(e) =>
                    handleResponseChange(request.id, e.target.value)
                  }
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addResponse(request.id)}
                    disabled={
                      !responses[request.id] ||
                      responses[request.id].trim() === ""
                    }
                    sx={{ alignSelf: "flex-start" }}
                  >
                    対応を追加
                  </Button>
                </Box>
                {request.responses && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">対応履歴</Typography>
                    {request.responses.map((response, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 1 }}
                        >
                          <Avatar
                            alt="Responder"
                            src={users[response.responderId]?.avatar}
                            sx={{ width: 24, height: 24, mr: 1 }}
                          />
                          {response.respondedAt && (
                            <Typography variant="body2" color="textSecondary">
                              {response.respondedAt.toDate
                                ? dayjs(response.respondedAt.toDate()).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  ) // Timestamp 型
                                : dayjs(response.respondedAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}{" "}
                              // 文字列型
                            </Typography>
                          )}

                          <IconButton
                            size="small"
                            onClick={() =>
                              handleEditDialogOpen({
                                ...response,
                                requestId: request.id,
                              })
                            }
                            sx={{ ml: 1 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteDialogOpen(response)}
                            sx={{ ml: 1 }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {response.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
      )}
      {tabValue === 1 && (
        <List>
          {resolvedRequests.map((request) => (
            <Accordion
              key={request.id}
              sx={{ mb: 2 }}
              onChange={() => handleAccordionChange(request.userId)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${request.id}-content`}
                id={`panel${request.id}-header`}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                  <Avatar
                    alt={request.name}
                    src={request.avatar || users[request.userId]?.avatar}
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="body1">
                    {request.name || users[request.userId]?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <Typography variant="h6">{request.category}</Typography>
                  <Chip label="解決済み" color="primary" sx={{ ml: 2 }} />
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {dayjs(request.createdAt.toDate()).fromNow()} (
                    {dayjs(request.createdAt.toDate()).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                    )
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleResolvedStatus(request.id, request.isResolved);
                  }}
                  sx={{ alignSelf: "flex-start", ml: "auto" }}
                >
                  未解決にする
                </Button>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 2 }}
                >
                  {request.message}
                </Typography>
                {request.loginEmail ? (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      ログインメール:
                    </Typography>
                    <Link href={`mailto:${request.loginEmail}`} variant="body2">
                      {request.loginEmail}
                    </Link>
                  </Box>
                ) : (
                  request.userId && (
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Typography variant="body2" sx={{ mr: 1 }}>
                        ログインメール:
                      </Typography>
                      <Link
                        href={`mailto:${users[request.userId]?.email}`}
                        variant="body2"
                      >
                        {users[request.userId]?.email}
                      </Link>
                    </Box>
                  )
                )}
                {request.replyEmail && (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      返信先メール:
                    </Typography>
                    <Link href={`mailto:${request.replyEmail}`} variant="body2">
                      {request.replyEmail}
                    </Link>
                  </Box>
                )}
                <TextField
                  fullWidth
                  label="対応を入力"
                  variant="outlined"
                  value={responses[request.id] || ""}
                  onChange={(e) =>
                    handleResponseChange(request.id, e.target.value)
                  }
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addResponse(request.id)}
                    disabled={
                      !responses[request.id] ||
                      responses[request.id].trim() === ""
                    }
                    sx={{ alignSelf: "flex-start" }}
                  >
                    対応を追加
                  </Button>
                </Box>
                {request.responses && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">対応履歴</Typography>
                    {request.responses.map((response, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 1 }}
                        >
                          <Avatar
                            alt="Responder"
                            src={users[response.responderId]?.avatar}
                            sx={{ width: 24, height: 24, mr: 1 }}
                          />
                          <Typography variant="body2" color="textSecondary">
                            {dayjs(response.respondedAt.toDate()).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}{" "}
                            - {users[response.responderId]?.name}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleEditDialogOpen({
                                ...response,
                                requestId: request.id,
                              })
                            }
                            sx={{ ml: 1 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteDialogOpen(response)}
                            sx={{ ml: 1 }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {response.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
      )}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        aria-labelledby="edit-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="edit-dialog-title">対応履歴の編集</DialogTitle>
        <DialogContent>
          <DialogContentText>編集内容を入力してください。</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="対応内容"
            type="text"
            fullWidth
            value={editingText}
            onChange={(e) => setEditingText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleEditSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">対応履歴の消去</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当に対応履歴を消去してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            消去
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ContactRequests;
