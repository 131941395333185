import React, { useState, useEffect } from "react";
import { Typography, Button, Box } from "@mui/material";
import PrefectureDialog from "./PrefectureDialog";
import PrefectureChat from "./PrefectureChat";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";

const RegionChatPage = () => {
  const [user, setUser] = useState(null);
  const [prefectureDialogOpen, setPrefectureDialogOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        }
      }
    };

    fetchUser();
  }, []);

  const handlePrefectureUpdated = (message) => {
    console.log(message); // 必要に応じて通知や再レンダリングを実装
    setUser((prev) => ({ ...prev, isRegisterPrefecture: true }));
  };

  if (!user) {
    return <Typography>ユーザー情報を取得しています...</Typography>;
  }

  if (!user.prefecture) {
    return (
      <Box>
        <Typography variant="h6">居住地</Typography>
        {user.isRegisterPrefecture ? (
          <Box>
            <Typography
              variant="body1"
              className="text-2xl font-bold text-blue-500"
            >
              {user.prefecture}
            </Typography>
            <Typography variant="caption">
              ※一度決定した居住地は変更できません。
            </Typography>
          </Box>
        ) : (
          <Button
            variant="contained"
            onClick={() => setPrefectureDialogOpen(true)}
          >
            居住地を編集
          </Button>
        )}
        <PrefectureDialog
          open={prefectureDialogOpen}
          handleClose={() => setPrefectureDialogOpen(false)}
          onPrefectureUpdated={handlePrefectureUpdated}
        />
      </Box>
    );
  }

  // 都道府県から地域へのマッピング
  const prefectureToRegionMap = {
    北海道: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    青森県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    岩手県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    宮城県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    秋田県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    山形県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    福島県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    茨城県: { region: "関東", groupId: "kanto" },
    栃木県: { region: "関東", groupId: "kanto" },
    群馬県: { region: "関東", groupId: "kanto" },
    埼玉県: { region: "関東", groupId: "kanto" },
    千葉県: { region: "関東", groupId: "kanto" },
    東京都: { region: "関東", groupId: "kanto" },
    神奈川県: { region: "関東", groupId: "kanto" },
    新潟県: { region: "中部", groupId: "chubu" },
    富山県: { region: "中部", groupId: "chubu" },
    石川県: { region: "中部", groupId: "chubu" },
    福井県: { region: "中部", groupId: "chubu" },
    山梨県: { region: "中部", groupId: "chubu" },
    長野県: { region: "中部", groupId: "chubu" },
    岐阜県: { region: "中部", groupId: "chubu" },
    静岡県: { region: "中部", groupId: "chubu" },
    愛知県: { region: "中部", groupId: "chubu" },
    三重県: { region: "近畿", groupId: "kinki" },
    滋賀県: { region: "近畿", groupId: "kinki" },
    京都府: { region: "近畿", groupId: "kinki" },
    大阪府: { region: "近畿", groupId: "kinki" },
    兵庫県: { region: "近畿", groupId: "kinki" },
    奈良県: { region: "近畿", groupId: "kinki" },
    和歌山県: { region: "近畿", groupId: "kinki" },
    鳥取県: { region: "中国", groupId: "chugoku" },
    島根県: { region: "中国", groupId: "chugoku" },
    岡山県: { region: "中国", groupId: "chugoku" },
    広島県: { region: "中国", groupId: "chugoku" },
    山口県: { region: "中国", groupId: "chugoku" },
    徳島県: { region: "四国", groupId: "shikoku" },
    香川県: { region: "四国", groupId: "shikoku" },
    愛媛県: { region: "四国", groupId: "shikoku" },
    高知県: { region: "四国", groupId: "shikoku" },
    福岡県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    佐賀県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    長崎県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    熊本県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    大分県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    宮崎県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    鹿児島県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    沖縄県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    // 特別な場合があればここに追加
    "海外（アメリカ）": { region: "海外", groupId: "overseas" },
    "海外（その他）": { region: "海外", groupId: "overseas" },
  };

  const getRegionInfo = (prefecture) => {
    return (
      prefectureToRegionMap[prefecture] || {
        region: "不明",
        groupId: "unknown",
      }
    );
  };

  const regionInfo = getRegionInfo(user.prefecture);

  return (
    <Box>
      <Typography variant="h5">{regionInfo.region}チャット</Typography>
      <PrefectureChat groupId={regionInfo.groupId} />
    </Box>
  );
};

export default RegionChatPage;
