import React, { useEffect, useState } from 'react';
import { Button, Typography, CircularProgress, Container, Box } from '@mui/material';
import { collection, getDoc,getDocs, doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../api/firebase'; // Firebaseのセットアップを使用
import { useNavigate } from 'react-router-dom'; // React Router を使用

const TokenInfoPage = () => {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [checkingAdmin, setCheckingAdmin] = useState(true);
  const [tokenInfo, setTokenInfo] = useState({
    total: 0,
    price28000: 0,
    price39000: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const checkIfAdmin = async () => {
      setCheckingAdmin(true);
      try {
        const userUid = auth.currentUser?.uid;
        if (!userUid) {
          console.error('User is not authenticated.');
          navigate('/home');
          return;
        }

        const userDocRef = doc(db, 'users', userUid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (!userData.isAdmin) {
            navigate('/home');
          }
        } else {
          console.error('User document not found.');
          navigate('/home');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/home');
      } finally {
        setCheckingAdmin(false);
      }
    };

    checkIfAdmin();
  }, [navigate]);

  const fetchTokenInfo = async () => {
    setLoading(true);

    try {
      const tokenCollection = collection(db, 'token2generation');
      const snapshot = await getDocs(tokenCollection);

      let total = 0;
      let price28000 = 0;
      let price39000 = 0;

      snapshot.forEach((doc) => {
        const data = doc.data();
        total += 1;

        if (data.price === '¥28,000') {
          price28000 += 1;
        }
        if (data.price === '¥39,000') {
          price39000 += 1;
        }
      });

      setTokenInfo({ total, price28000, price39000 });
    } catch (error) {
      console.error('Error fetching token data:', error);
    } finally {
      setLoading(false);
    }
  };

  const updatePrice28000To39000 = async () => {
    setUpdating(true);

    try {
      const tokenCollection = collection(db, 'token2generation');
      const snapshot = await getDocs(tokenCollection);

      const updates = snapshot.docs.filter(doc => doc.data().price === '¥28,000');
      for (const docSnap of updates) {
        const docRef = doc(db, 'token2generation', docSnap.id);
        await updateDoc(docRef, { price: '¥39,000' });
      }

      // 更新後にデータを再取得
      fetchTokenInfo();
    } catch (error) {
      console.error('Error updating token data:', error);
    } finally {
      setUpdating(false);
    }
  };

  if (checkingAdmin) {
    return (
      <Container maxWidth="sm" style={{ marginTop: '50px', textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          管理者ステータスを確認しています...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Box textAlign="center" marginBottom={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          トークン情報取得ページ
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Firestore からトークン情報を取得して表示します。
        </Typography>
      </Box>

      <Box textAlign="center" marginBottom={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchTokenInfo}
          disabled={loading}
          style={{ marginRight: '10px' }}
        >
          {loading ? '取得中...' : 'トークン情報取得'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={updatePrice28000To39000}
          disabled={updating}
        >
          {updating ? '更新中...' : '¥28,000 を ¥39,000 に変更'}
        </Button>
        {(loading || updating) && (
          <CircularProgress size={24} style={{ marginLeft: '15px' }} />
        )}
      </Box>

      {!loading && !updating && (
        <Box>
          <Typography variant="h6" gutterBottom>
            結果
          </Typography>
          <Typography>すべてのトークン数: {tokenInfo.total}</Typography>
          <Typography>¥28,000 の数: {tokenInfo.price28000}</Typography>
          <Typography>¥39,000 の数: {tokenInfo.price39000}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default TokenInfoPage;
