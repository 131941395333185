import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db, auth } from "../api/firebase"; // auth をインポート
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // useNavigate をインポート

const AddBankTransferPayments = () => {
  const [uids, setUids] = useState("");
  const [paymentPhase, setPaymentPhase] = useState("1"); // 初期値は "1"
  const [amountTotal, setAmountTotal] = useState("28000"); // 初期値は "28000"
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // navigate を初期化

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const currentUser = auth.currentUser;

        if (!currentUser) {
          navigate("/home"); // 未認証の場合はリダイレクト
          return;
        }

        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists() || !userDoc.data().isAdmin) {
          navigate("/home"); // isAdmin が false または存在しない場合はリダイレクト
          return;
        }
      } catch (error) {
        console.error("認証チェック中にエラーが発生しました:", error);
        navigate("/home"); // エラーが発生した場合も安全のためリダイレクト
      }
    };

    checkAdmin();
  }, [navigate]);

  const handleAddPayments = async () => {
    setProcessing(true);
    setMessage("");
    setErrorMessage("");

    const uidList = uids.split("\n").map((uid) => uid.trim()).filter(Boolean); // 改行区切りでUIDを取得

    if (uidList.length === 0) {
      setErrorMessage("UIDを入力してください。");
      setProcessing(false);
      return;
    }

    try {
      let updatedCount = 0;

      for (const uid of uidList) {
        try {
          const userRef = doc(db, "users", uid);
          const userDoc = await getDoc(userRef);

          if (!userDoc.exists()) {
            console.warn(`UID: ${uid} のユーザードキュメントが見つかりませんでした。`);
            continue;
          }

          // payments 配列に新しい情報を追加
          await updateDoc(userRef, {
            payments: arrayUnion({
              paymentPhase: paymentPhase, // ラジオボタンで選択された支払いフェーズ
              amountTotal: Number(amountTotal), // 選択された金額
              timestamp: new Date(), // 現在の日時
              paymentMethod: "銀行振込", // 支払い方法
            }),
          });

          updatedCount++;
        } catch (error) {
          console.error(`UID: ${uid} の更新中にエラーが発生しました:`, error);
        }
      }

      setMessage(`${updatedCount} 件のユーザーに支払い情報を追加しました。`);
    } catch (error) {
      console.error("全体的なエラー:", error);
      setErrorMessage("エラーが発生しました。詳細はコンソールをご確認ください。");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ textAlign: "center", mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        銀行振込情報をユーザーに追加
      </Typography>

      <RadioGroup
        row
        value={paymentPhase}
        onChange={(e) => setPaymentPhase(e.target.value)}
        sx={{ justifyContent: "center", mb: 2 }}
      >
        <FormControlLabel value="1" control={<Radio />} label="1期" />
        <FormControlLabel value="2" control={<Radio />} label="2期" />
        <FormControlLabel value="3" control={<Radio />} label="3期" />
      </RadioGroup>

      <RadioGroup
        row
        value={amountTotal}
        onChange={(e) => setAmountTotal(e.target.value)}
        sx={{ justifyContent: "center", mb: 2 }}
      >
        <FormControlLabel value="28000" control={<Radio />} label="¥28,000" />
        <FormControlLabel value="39000" control={<Radio />} label="¥39,000" />
        <FormControlLabel value="55000" control={<Radio />} label="¥55,000" />
      </RadioGroup>

      <TextField
        label="UIDを改行で入力"
        multiline
        rows={8}
        fullWidth
        variant="outlined"
        value={uids}
        onChange={(e) => setUids(e.target.value)}
        placeholder="uidを貼り付ける"
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddPayments}
        disabled={processing}
      >
        支払い情報を追加
      </Button>
      {processing && (
        <Box sx={{ mt: 2 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            処理中です。しばらくお待ちください...
          </Typography>
        </Box>
      )}
      {message && (
        <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
      {errorMessage && (
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default AddBankTransferPayments;
