import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { doc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import PrefectureDialog from "./PrefectureDialog";

const PrefectureContainer = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentPrefecture, setCurrentPrefecture] = useState("");
  const [currentMunicipality, setCurrentMunicipality] = useState(""); // 市区町村
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // 居住地データのリアルタイム監視
  useEffect(() => {
    const userDoc = doc(db, "users", auth.currentUser.uid);

    const unsubscribe = onSnapshot(userDoc, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setCurrentPrefecture(data.prefecture || "未設定");
        setCurrentMunicipality(data.municipality || "未設定");
      } else {
        setCurrentPrefecture("未設定");
        setCurrentMunicipality("未設定");
      }
      setLoading(false);
    });

    return () => unsubscribe(); // クリーンアップ
  }, []);

  // ダイアログを開く
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // ダイアログを閉じる
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // 居住地登録完了メッセージを設定
  const handlePrefectureUpdated = (message) => {
    setSnackbarMessage(message);
  };

  // スナックバーを閉じる
  const handleSnackbarClose = () => {
    setSnackbarMessage("");
  };

  const accordionTitle =
    currentPrefecture !== "未設定"
      ? `居住地: ${currentPrefecture}${currentPrefecture === "北海道" && currentMunicipality !== "未設定" ? ` - ${currentMunicipality}` : ""}`
      : "居住地の設定";

  return (
    <Accordion sx={{ width: "100%", marginBottom: "12px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "grey.700" }}>
          {accordionTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding={1}
          sx={{
            border: 1,
            borderColor: "grey.300",
            borderRadius: 1,
            boxShadow: 1,
            backgroundColor: "white",
          }}
        >
          {loading ? (
            <CircularProgress sx={{ mb: 2 }} />
          ) : (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                現在の設定:{" "}
                <strong>
                  {currentPrefecture !== "未設定"
                    ? `${currentPrefecture}${
                        currentPrefecture === "北海道" && currentMunicipality !== "未設定"
                          ? ` - ${currentMunicipality}`
                          : ""
                      }`
                    : "未設定"}
                </strong>
              </Typography>
              {currentPrefecture !== "未設定" ? (
                <Alert severity="info" sx={{ width: "100%", mb: 2 }}>
                  既に居住地設定済みです。
                </Alert>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDialogOpen}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  居住地を設定する
                </Button>
              )}
            </>
          )}
          <PrefectureDialog
            open={dialogOpen}
            handleClose={handleDialogClose}
            onPrefectureUpdated={handlePrefectureUpdated}
          />
          <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PrefectureContainer;
