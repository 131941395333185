// import React, { useState, useEffect } from "react";
// import {
//   Modal,
//   Box,
//   Typography,
//   Button,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   TextField,
// } from "@mui/material";
// import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
// import { db } from "../api/firebase";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
// import "../css/WelcomeModal.css"; // スクロールアニメーション用のスタイル
// import { ThankYouModal } from "./ThankYouModal"; // 新しいコンポーネントのインポート

// export const WelcomeModal = ({ userId }) => {
//   const [open, setOpen] = useState(false);
//   const [step, setStep] = useState(0);
//   const [name, setName] = useState("");
//   const [purpose, setPurpose] = useState(""); // 目的の状態を管理する
//   const [showThankYouModal, setShowThankYouModal] = useState(false); // 新しいモーダルの状態を管理する

//   useEffect(() => {
//     if (!userId) return; // userIdが無効な場合は早期リターン

//     const checkFirstVisit = async () => {
//       try {
//         const userDocRef = doc(db, "users", userId);
//         const userDoc = await getDoc(userDocRef);
//         if (userDoc.exists() && !userDoc.data().hasSeenWelcome) {
//           setOpen(true); // モーダルを開く
//         }
//       } catch (error) {
//         console.error("Error checking first visit:", error);
//       }
//     };

//     checkFirstVisit();

//     const unsubscribe = onSnapshot(doc(db, "users", userId), (docSnapshot) => {
//       if (docSnapshot.exists()) {
//         const data = docSnapshot.data();
//         if (
//           data.hasSeenWelcome &&
//           (data.hasSeenThankYou === undefined || data.hasSeenThankYou === false)
//         ) {
//           setShowThankYouModal(true); // `hasSeenThankYou`が`false`または存在しない場合に新しいモーダルを表示する
//         }
//       }
//     });

//     return () => unsubscribe(); // クリーンアップ
//   }, [userId]);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleNext = () => {
//     setStep((prevStep) => prevStep + 1);
//   };

//   const handleBack = () => {
//     setStep((prevStep) => prevStep - 1);
//   };

//   const handleConfirm = async () => {
//     try {
//       const userDocRef = doc(db, "users", userId);
//       await updateDoc(userDocRef, {
//         hasSeenWelcome: true,
//         name: name,
//         purpose: purpose,
//       });
//       handleClose();
//     } catch (error) {
//       console.error("Error updating document:", error);
//     }
//   };

//   const renderContent = () => {
//     switch (step) {
//       case 0:
//         return (
//           <>
//             <Typography id="welcome-modal-title" variant="h6" component="h2">
//               ようこそ Mastermind へ!
//             </Typography>
//             <Typography id="welcome-modal-description" sx={{ mt: 2 }}>
//               MasterMindはアナタのDDPを叶えるお手伝いをします。
//             </Typography>
//             <Button onClick={handleNext} style={{ marginTop: 20 }}>
//               次へ
//             </Button>
//           </>
//         );
//       case 1:
//         return (
//           <>
//             <Typography id="welcome-modal-title" variant="h6" component="h2">
//               名前を入力してください
//             </Typography>
//             <TextField
//               label="名前"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               fullWidth
//               margin="normal"
//             />
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginTop: 2,
//               }}
//             >
//               <Button onClick={handleBack}>戻る</Button>
//               <Button onClick={handleNext} disabled={!name}>
//                 次へ
//               </Button>
//             </Box>
//           </>
//         );
//       case 2:
//         return (
//           <>
//             <Typography id="welcome-modal-title" variant="h6" component="h2">
//               あなたのMasterMindでの目的を教えて下さい
//             </Typography>
//             <Typography sx={{ mt: 1, color: "red", fontWeight: "bold" }}>
//               こちらの目的設定は暫定です。どちらで答えても今後の活動に影響ありません。
//               今後の活動についてはまた新たに目的を伺います。よろしくお願いします。
//             </Typography>
//             <RadioGroup
//               value={purpose}
//               onChange={(e) => setPurpose(e.target.value)}
//               style={{ marginTop: 16 }}
//             >
//               <FormControlLabel
//                 value="音源のダウンロードのみ"
//                 control={<Radio />}
//                 label="音源のダウンロードのみ"
//               />
//               <FormControlLabel
//                 value="音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"
//                 control={<Radio />}
//                 label="音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"
//               />
//             </RadioGroup>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginTop: 2,
//               }}
//             >
//               <Button onClick={handleBack}>戻る</Button>
//               <Button onClick={handleNext} disabled={!purpose}>
//                 次へ
//               </Button>
//             </Box>
//           </>
//         );
//       case 3:
//         return (
//           <>
//             <Typography id="welcome-modal-title" variant="h6" component="h2">
//               以下の内容でよろしいですか？
//             </Typography>
//             <Typography sx={{ mt: 2 }}>名前: {name}</Typography>
//             <Typography sx={{ mt: 2 }}>
//               目的:{" "}
//               {purpose === "音源のダウンロードのみ"
//                 ? "音源のダウンロードのみ"
//                 : "音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"}
//             </Typography>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginTop: 2,
//               }}
//             >
//               <Button onClick={handleBack}>戻る</Button>
//               <Button onClick={handleConfirm}>確認</Button>
//             </Box>
//           </>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <>
//       <Modal
//         open={open}
//         aria-labelledby="welcome-modal-title"
//         aria-describedby="welcome-modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "90%", // 幅を90%に設定
//             maxWidth: 400, // 最大幅を400に設定
//             height: 290,
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//             overflowY: "auto", // コンテンツがボックス外に出る場合はスクロール可能にする
//           }}
//         >
//           <TransitionGroup>
//             <CSSTransition key={step} timeout={300} classNames="slide">
//               <div className="step-content">{renderContent()}</div>
//             </CSSTransition>
//           </TransitionGroup>
//         </Box>
//       </Modal>
//       {showThankYouModal && (
//         <ThankYouModal
//           open={showThankYouModal}
//           onClose={() => setShowThankYouModal(false)}
//           userId={userId}
//         />
//       )}
//     </>
//   );
// };



import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../api/firebase";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../css/WelcomeModal.css"; // スクロールアニメーション用のスタイル
import { ThankYouModal } from "./ThankYouModal"; // 新しいコンポーネントのインポート

export const WelcomeModal = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [showThankYouModal, setShowThankYouModal] = useState(false); // 新しいモーダルの状態を管理する

  useEffect(() => {
    if (!userId) return; // userIdが無効な場合は早期リターン

    const checkFirstVisit = async () => {
      try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && !userDoc.data().hasSeenWelcome) {
          setOpen(true); // モーダルを開く
        }
      } catch (error) {
        console.error("Error checking first visit:", error);
      }
    };

    checkFirstVisit();

    const unsubscribe = onSnapshot(doc(db, "users", userId), (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (
          data.hasSeenWelcome &&
          (data.hasSeenThankYou === undefined || data.hasSeenThankYou === false)
        ) {
          setShowThankYouModal(true); // `hasSeenThankYou`が`false`または存在しない場合に新しいモーダルを表示する
        }
      }
    });

    return () => unsubscribe(); // クリーンアップ
  }, [userId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleConfirm = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        hasSeenWelcome: true,
        name: name,
      });
      handleClose();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography id="welcome-modal-title" variant="h6" component="h2">
              ようこそ Mastermind へ!
            </Typography>
            <Typography id="welcome-modal-description" sx={{ mt: 2 }}>
              MasterMindはアナタのDDPを叶えるお手伝いをします。
            </Typography>
            <Button onClick={handleNext} style={{ marginTop: 20 }}>
              次へ
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <Typography id="welcome-modal-title" variant="h6" component="h2">
              名前を入力してください
            </Typography>
            <TextField
              label="名前"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Button onClick={handleBack}>戻る</Button>
              <Button onClick={handleNext} disabled={!name}>
                次へ
              </Button>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Typography id="welcome-modal-title" variant="h6" component="h2">
              以下の内容でよろしいですか？
            </Typography>
            <Typography sx={{ mt: 2 }}>名前: {name}</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Button onClick={handleBack}>戻る</Button>
              <Button onClick={handleConfirm}>確認</Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="welcome-modal-title"
        aria-describedby="welcome-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%", // 幅を90%に設定
            maxWidth: 400, // 最大幅を400に設定
            height: 290,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflowY: "auto", // コンテンツがボックス外に出る場合はスクロール可能にする
          }}
        >
          <TransitionGroup>
            <CSSTransition key={step} timeout={300} classNames="slide">
              <div className="step-content">{renderContent()}</div>
            </CSSTransition>
          </TransitionGroup>
        </Box>
      </Modal>
      {showThankYouModal && (
        <ThankYouModal
          open={showThankYouModal}
          onClose={() => setShowThankYouModal(false)}
          userId={userId}
        />
      )}
    </>
  );
};
