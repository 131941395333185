import React, { useState } from "react";
import { TextField, Button, Box, Snackbar, LinearProgress, Typography } from "@mui/material";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../api/firebase";
import { sha256 } from "crypto-hash"; // npm install crypto-hash を事前に実行

const ImportDataComponent = () => {
  const [inputData, setInputData] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleImport = async () => {
    if (!inputData.trim()) {
      setSnackbarMessage("データを入力してください");
      setIsSnackbarOpen(true);
      return;
    }

    const rows = inputData.trim().split("\n");
    const total = rows.length;
    let addedCount = 0;
    let skippedCount = 0;

    setTotalCount(total);
    setCurrentCount(0);
    setIsProcessing(true);
    setProgress(0);
    setEstimatedTime(0);

    const startTime = Date.now();

    try {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const [timestamp, wish, prefecture, name, email, ddpId] = row.split("\t");

        if (!timestamp || !wish || !prefecture || !name || !email || !ddpId) {
          console.error("データ形式が正しくありません:", row);
          skippedCount++;
          continue;
        }

        // `combinedKey` をハッシュ化して短縮
        const combinedKey = await sha256(`${timestamp}-${wish}-${prefecture}-${name}-${email}-${ddpId}`);

        // Check for duplicates
        const q = query(
          collection(db, "reviewableDDPs"), // コレクション名を論理的な名前に変更
          where("combinedKey", "==", combinedKey)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          // Add to Firestore
          await addDoc(collection(db, "reviewableDDPs"), {
            timestamp,
            wish,
            prefecture,
            name,
            email,
            ddpId,
            combinedKey, // ハッシュ化されたキーを保存
          });
          addedCount++;
        } else {
          skippedCount++;
        }

        // Update progress and estimated time
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        const avgTimePerItem = elapsedTime / (i + 1);
        const remainingTime = avgTimePerItem * (total - i - 1);

        setProgress(((i + 1) / total) * 100);
        setCurrentCount(i + 1);
        setEstimatedTime(Math.ceil(remainingTime / 1000)); // Convert ms to seconds
      }

      setSnackbarMessage(
        `${addedCount}件登録しました。${skippedCount}件は既に登録済みなのでスルーしました。`
      );
    } catch (error) {
      console.error("データの取り込み中にエラーが発生しました:", error);
      setSnackbarMessage("データの取り込み中にエラーが発生しました");
    } finally {
      setIsProcessing(false);
      setIsSnackbarOpen(true);
      setProgress(0); // Reset progress
      setEstimatedTime(0); // Reset estimated time
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} maxWidth={600} mx="auto">
      <TextField
        label="スプレッドシートデータ"
        multiline
        rows={10}
        variant="outlined"
        value={inputData}
        onChange={(e) => setInputData(e.target.value)}
        placeholder="タイムスタンプ\t願い\t都道府県\t名前\tメールアドレス\tDDPID"
      />
      {isProcessing && (
        <>
          <Typography variant="body1">
            処理中: {currentCount}件 / {totalCount}件
          </Typography>
          <Typography variant="body2">
            推定残り時間: {estimatedTime}秒
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleImport}
        disabled={isProcessing}
      >
        {isProcessing ? "処理中..." : "取り込み"}
      </Button>
      <Snackbar
        open={isSnackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        autoHideDuration={6000}
      />
    </Box>
  );
};

export default ImportDataComponent;
