// src/components/Group.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fab from "@mui/material/Fab";
import SurveyIcon from "@mui/icons-material/QuestionAnswer";
import Drawer from "@mui/material/Drawer";
import BottomNavBar from "./BottomNavBar";
import GroupMembers from "./GroupMembers";
import DDPSetting from "./DDPSetting";
import GroupChatEx from "./GroupChatEx";
import PrefectureChat from "./PrefectureChat";
import Webinar from "./Webinar";
import SophieMission from "./SophieMission";
import SurveyDialog from "./SurveyDialog";

const Group = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);
  const [currentView, setCurrentView] = useState("仲間");
  const [user, setUser] = useState(null);
  const [isMissionDrawerOpen, setMissionDrawerOpen] = useState(false);
  const [isSurveyDialogOpen, setSurveyDialogOpen] = useState(false);
  const [isFabVisible, setFabVisible] = useState(true);

  // 選択されたチャットタイプを管理するステートを追加
  // const [selectedChat, setSelectedChat] = useState("Chat");

  const surveyId = "202412Last";
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const topOffset = isSmallScreen ? "56px" : "64px";

  useEffect(() => {
    const checkSurveyResponse = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser(userData);

          const userSurveyQuery = query(
            collection(db, "users", userId, "fab"),
            where("surveyId", "==", surveyId)
          );
          const userSurveySnap = await getDocs(userSurveyQuery);
          setFabVisible(userSurveySnap.empty);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    };

    checkSurveyResponse();
  }, [surveyId]);

  useEffect(() => {
    const fetchGroup = async () => {
      if (!user) return;

      setLoading(true);
      try {
        if (!user.isAdmin && user.participatingGroup !== id) {
          navigate(`/group/${user.participatingGroup}`);
          return;
        }

        const docRef = doc(db, "groups", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setGroup(docSnap.data());
        } else {
          setError("Group not found");
        }
      } catch (err) {
        setError("Failed to fetch group");
      } finally {
        setLoading(false);
      }
    };

    fetchGroup();
  }, [id, user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  const handleMissionClick = () => {
    setMissionDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setMissionDrawerOpen(false);
  };

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);

    // ナビゲーションの選択に応じてビューを変更
    if (newValue === 0) {
      setCurrentView("仲間");
      // setSelectedChat("Chat"); // ラベルをリセット
    } else if (newValue === 1) {
      setCurrentView("DDP");
      // setSelectedChat("Chat"); // ラベルをリセット
    } else if (newValue === 2) {
      setCurrentView("グループチャット");
      // Chat ボタンが押されたときはメニューを表示するので、currentView は変更しない
    } else if (newValue === 3) {
      setCurrentView("1/13");
      // setSelectedChat("Chat"); // ラベルをリセット
    } else if (newValue === 4) {
      // ソフィーのミッションボタンは別途処理
    }
  };



  const handleSurveyClick = async () => {
    if (!user) return;

    try {
      const userSurveyQuery = query(
        collection(db, "users", user.uid, "fab"),
        where("surveyId", "==", surveyId)
      );
      const userSurveySnap = await getDocs(userSurveyQuery);

      if (userSurveySnap.empty) {
        setSurveyDialogOpen(true);
      } else {
        setFabVisible(false);
        console.log("ユーザーはすでにアンケートに回答済みです");
      }
    } catch (error) {
      console.error("アンケート回答のチェックに失敗しました", error);
    }
  };

  const handleSurveyClose = () => {
    setSurveyDialogOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}
    >
      <BottomNavBar
        value={value}
        onChange={handleNavigationChange}
        handleMissionClick={handleMissionClick}
        // user={user}
        topOffset={topOffset}
      />

      <Fab
        id="survey-fab"
        color="primary"
        aria-label="アンケート"
        onClick={handleSurveyClick}
        style={{
          position: "fixed",
          bottom: "80px",
          right: "16px",
          zIndex: 1100,
          display: isFabVisible ? "block" : "none",
        }}
      >
        <SurveyIcon />
      </Fab>

      <div style={{ flex: 1, marginTop: topOffset }}>
        {currentView === "仲間" && <GroupMembers group={group} />}
        {currentView === "DDP" && <DDPSetting />}
        {currentView === "グループチャット" && <GroupChatEx groupId={id} />}
        {currentView === "1/13" && <Webinar />}
      </div>

      <Drawer
        anchor="right"
        open={isMissionDrawerOpen}
        onClose={handleDrawerClose}
      >
        <SophieMission onClose={handleDrawerClose} />
      </Drawer>

      <SurveyDialog
        open={isSurveyDialogOpen}
        onClose={handleSurveyClose}
        surveyId={surveyId}
        groupId={id}
        userId={auth.currentUser?.uid}
      />
    </div>
  );
};

export default Group;
