import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./component/Home";
import AuthProvider from "./context/auth";
import PrivateRoute from "./component/PrivateRoute";
import Welcome from "./component/Welcome";

import { memo, useEffect } from "react";
import TermsOfService from "./component/TermsOfService";
import PrivacyPolicy from "./component/PrivacyPolicy";
import GoalForm from "./forms/GoalForm";
import Profile from "./component/Profile";
import AdminSetting from "./component/AdminSetting";
import DDP from "./component/DDP";
import SupportPage from "./component/SupportPage";
import ContactConfirmationPage from "./component/ContactConfirmationPage";
import Music from "./component/Music";
import GroupingTest from "./component/GroupingTest";
import ContactRequests from "./component/ContactRequests";
import UsersList from "./component/UsersList";
import UsersListEx from "./component/UserListEx";

import UserVerification from "./component/UserVerification";
import AnnouncementList from "./component/AnnouncementList";
import VideoPage from "./component/VideoPage";
import GroupManagement from "./component/GroupManagement";
import MapComponent from "./component/MapComponent";
import HokkaidoMapComponent from "./component/HokkaidoMapComponent";
import Group from "./component/Group";
import GroupMemberCustom from "./component/GroupMemberCustom";
import GroupManagementEx from "./component/GroupManagementEx";
import NotVerified from "./component/NotVerified";
import ChannelLoader from "./component/ChannelLoader";
import GroupsList from "./component/GroupsList";
import ChannelLoaderEx from "./component/ChannelLoaderEx";
import PaymentSuccess from "./component/PaymentSuccess";
import PaymentCancel from "./component/PaymentCancel";
import TokenRegistration from "./component/TokenRegistration";
import TokenVerificationPage from "./component/TokenVerificationPage";
import VerifiedUserCount from "./component/VerifiedUserCount";
import AddBankTransferPayments from "./component/AddBankTransferPayments";
import PostManagement from "./component/PostManagement";
import VideoManagement from "./component/VideoManagement";
import TokenViewer from "./component/TokenViewer";
import ImportDDPComponent from "./component/ImportDDPComponent";
import ReviewDDP from "./component/ReviewDDP";
import TokenInfoPage from "./component/TokenInfoPage";
import UserStatsPage from "./component/UserStatsPage";
import PrefectureChatPage from "./component/PrefectureChatPage";
import RegionChatPage from "./component/RegionChatPage";
import SpecificCategoryRequests from "./component/SpecificCategoryRequests";


function App() {
  // useAppLifecycle();
  // useAuthListener(); // デバイス登録
  // useUpdateDeviceStatusOnVisibilityChange(); // VisibilityChange関数

  // useEffect(() => {
  //   requestForToken();
  // }, []);

  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/" element={<Welcome />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/notverified" element={<NotVerified />} />
            <Route path="/supportpage" element={<SupportPage />} />
            <Route path="/termsofservice" element={<TermsOfService />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />

            <Route element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/adminSetting" element={<AdminSetting />} />
              <Route path="/ddp" element={<DDP />} />
              <Route path="/videos" element={<VideoPage />} />
              <Route path="/verifing" element={<TokenVerificationPage />} />
              <Route path="/announcements" element={<AnnouncementList />} />
              <Route path="/support" element={<SupportPage />} />
              <Route path="/group/:id" element={<Group />} />
              {/* <Route path="/generate" element={<VerifiedUserCount />} /> */}
              <Route path="/reviewDDP" element={<ReviewDDP />} />



              <Route path="/admin/music" element={<Music />} />
              <Route path="/admin/usersMap" element={<MapComponent />} />
              <Route path="/admin/usersMapHokkaido" element={<HokkaidoMapComponent />} />
              <Route path="/admin/grouping" element={<GroupManagementEx />} />
              <Route path="/admin/groups" element={<GroupManagementEx />} />
              <Route path="/admin/groupMemberCustom" element={<GroupMemberCustom />} />
              <Route path="/admin/support" element={<ContactRequests />} />
              <Route path="/admin/checkToken" element={<TokenViewer />} />
              <Route path="/admin/userlist" element={<UsersListEx />} />
              <Route path="/admin/groupList" element={<GroupsList />} />
              <Route path="/admin/groupSearch" element={<ChannelLoader />} />
              <Route path="/admin/groupSearchEx" element={<ChannelLoaderEx />} />
              <Route path="/admin/addBankTransferPayment" element={<AddBankTransferPayments />} />
              <Route path="/admin/tokenRegistration" element={<TokenRegistration />} />
              <Route path="/admin/postManagement" element={<PostManagement />} />
              <Route path="/admin/videoManagement" element={<VideoManagement />} />

              <Route path="/admin/importDDP" element={<ImportDDPComponent />} />
              <Route path="/admin/tokenInfoPage" element={<TokenInfoPage />} />
              <Route path="/admin/userStatusPage" element={<UserStatsPage />} />

              <Route path="/admin/SpecificCategoryRequests" element={<SpecificCategoryRequests />} />


              <Route path="/payment/success" element={<PaymentSuccess />} />
              <Route path="/payment/cancel" element={<PaymentCancel />} />
              
              <Route path="/prefecture-chat" element={<PrefectureChatPage  />} />
              <Route path="/region-chat" element={<RegionChatPage  />} />

              {/* <Route path="/admin/userlist" element={<UsersList />} /> */}
              <Route path="/admin/verification" element={<UserVerification />} />
            <Route path="/contact-confirmation" element={<ContactConfirmationPage />} />



              <Route path="/goal" element={<GoalForm />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default memo(App);
