import React, { useEffect, useState } from 'react';
import { Button, Typography, CircularProgress, Container, Box } from '@mui/material';
import { collection, getDocs, updateDoc, doc ,getDoc} from 'firebase/firestore';
import { db, auth } from '../api/firebase'; // Firebaseのセットアップを使用
import { useNavigate } from 'react-router-dom'; // React Router を使用

const UserStatsPage = () => {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [checkingAdmin, setCheckingAdmin] = useState(true);
  const [userStats, setUserStats] = useState({
    price28000NoPayments: 0,
    hasPaymentPhase1NoPhase2: 0, // 新規追加
  });

  const navigate = useNavigate();

  useEffect(() => {
    const checkIfAdmin = async () => {
      setCheckingAdmin(true);
      try {
        const userUid = auth.currentUser?.uid;
        if (!userUid) {
          console.error('User is not authenticated.');
          navigate('/home');
          return;
        }

        const userDocRef = doc(db, 'users', userUid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (!userData.isAdmin) {
            navigate('/home');
          }
        } else {
          console.error('User document not found.');
          navigate('/home');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/home');
      } finally {
        setCheckingAdmin(false);
      }
    };

    checkIfAdmin();
  }, [navigate]);

  const fetchUserStats = async () => {
    setLoading(true);

    try {
      const userCollection = collection(db, 'users');
      const allUsersSnapshot = await getDocs(userCollection);

      const price28000NoPayments = allUsersSnapshot.docs.filter((doc) => {
        const data = doc.data();
        return (
          data.secondPhasePrice === '¥28,000' &&
          (!data.payments || data.payments.length === 0)
        );
      }).length;

      const hasPaymentPhase1NoPhase2 = allUsersSnapshot.docs.filter((doc) => {
        const data = doc.data();
        if (!data.payments || data.payments.length === 0) {
          return false;
        }

        const hasPhase1 = data.payments.some(payment => payment.paymentPhase === '1');
        const hasPhase2 = data.payments.some(payment => payment.paymentPhase === '2');

        return hasPhase1 && !hasPhase2;
      }).length;

      setUserStats({
        price28000NoPayments,
        hasPaymentPhase1NoPhase2,
      });
    } catch (error) {
      console.error('Error fetching user stats:', error);
    } finally {
      setLoading(false);
    }
  };

  const updatePhasePriceCondition1 = async () => {
    setUpdating(true);
    try {
      const userCollection = collection(db, 'users');
      const allUsersSnapshot = await getDocs(userCollection);

      const usersToUpdate = allUsersSnapshot.docs.filter((doc) => {
        const data = doc.data();
        return (
          data.secondPhasePrice === '¥28,000' &&
          (!data.payments || data.payments.length === 0)
        );
      });

      for (const userDoc of usersToUpdate) {
        await updateDoc(doc(db, 'users', userDoc.id), {
          secondPhasePrice: '¥39,000',
        });
      }

      console.log(`${usersToUpdate.length} users updated for condition 1.`);
    } catch (error) {
      console.error('Error updating users for condition 1:', error);
    } finally {
      setUpdating(false);
    }
  };

  const updatePhasePriceCondition2 = async () => {
    setUpdating(true);
    try {
      const userCollection = collection(db, 'users');
      const allUsersSnapshot = await getDocs(userCollection);

      const usersToUpdate = allUsersSnapshot.docs.filter((doc) => {
        const data = doc.data();
        if (!data.payments || data.payments.length === 0) {
          return false;
        }

        const hasPhase1 = data.payments.some(payment => payment.paymentPhase === '1');
        const hasPhase2 = data.payments.some(payment => payment.paymentPhase === '2');

        return hasPhase1 && !hasPhase2;
      });

      for (const userDoc of usersToUpdate) {
        await updateDoc(doc(db, 'users', userDoc.id), {
          secondPhasePrice: '¥39,000',
        });
      }

      console.log(`${usersToUpdate.length} users updated for condition 2.`);
    } catch (error) {
      console.error('Error updating users for condition 2:', error);
    } finally {
      setUpdating(false);
    }
  };

  if (checkingAdmin) {
    return (
      <Container maxWidth="sm" style={{ marginTop: '50px', textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          管理者ステータスを確認しています...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Box textAlign="center" marginBottom={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          ユーザ統計ページ
        </Typography>
        <Typography variant="body1" color="textSecondary">
          条件に基づいてユーザのデータを更新します。
        </Typography>
      </Box>

      <Box textAlign="center" marginBottom={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchUserStats}
          disabled={loading}
        >
          {loading ? '取得中...' : 'ユーザ数取得'}
        </Button>
        {loading && <CircularProgress size={24} style={{ marginLeft: '15px' }} />}
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          結果
        </Typography>
        <Typography>
          ¥28,000 で payments が空または存在しないユーザ数: {userStats.price28000NoPayments}
        </Typography>
        <Typography>
          paymentPhase が "1" を持つが "2" を持っていないユーザ数: {userStats.hasPaymentPhase1NoPhase2}
        </Typography>
      </Box>

      <Box textAlign="center" marginBottom={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={updatePhasePriceCondition1}
          disabled={updating}
        >
          {updating ? '更新中...' : '条件1で更新 (¥28,000 -> ¥39,000)'}
        </Button>
      </Box>

      <Box textAlign="center" marginBottom={4}>
        <Button
          variant="contained"
          color="secondary"
          onClick={updatePhasePriceCondition2}
          disabled={updating}
        >
          {updating ? '更新中...' : '条件2で更新 (Phase1のみ -> ¥39,000)'}
        </Button>
      </Box>
    </Container>
  );
};

export default UserStatsPage;
