import React, { useState, useEffect, memo } from "react";
import { auth, db } from "../api/firebase";
import { doc, getDoc } from "firebase/firestore";

import BackImage from "./BackImage";
import ProfilePicture from "./ProfilePicture";
import ProfileName from "./ProfileName";
import ProfileCard from "./ProfileCard";
import SelfIntroduction from "./SelfIntroduction";
import PaymentContainer from "./PaymentContainer";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Profile = memo(() => {
  const [user, setUser] = useState(null); // 初期値はnullに設定
  const [loading, setLoading] = useState(true); // データ読み込み中の状態を追跡
  const navigate = useNavigate(); // ルート遷移用

  useEffect(() => {
    const fetchUser = async () => {
      if (!auth.currentUser) return; // ユーザーが未ログインの場合は処理を中断
      try {
        const userDocRef = doc(db, "users", auth.currentUser.uid); // ドキュメント参照
        const userSnapshot = await getDoc(userDocRef); // データ取得
        if (userSnapshot.exists()) {
          setUser(userSnapshot.data()); // ステートにデータをセット
        } else {
          console.error("User document does not exist.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // ローディング終了
      }
    };

    fetchUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // ローディング中の表示
  }

  return (
    <>
      <div className="flex flex-col w-full my-5">
        <div className="relative rounded-md">
          <BackImage id={auth.currentUser?.uid} />
          <ProfilePicture id={auth.currentUser?.uid} style={{ zIndex: 10 }} />
        </div>
        <ProfileName user={user} /> {/* ProfileNameにuserを渡す */}
        <ProfileCard user={user} /> {/* ProfileCardにuserを渡す */}
        <SelfIntroduction user={user} />{" "}
        {/* 必要に応じて他のコンポーネントにも渡す */}
        {!user?.isSekaimura &&
          (user?.isVerified ? (
            <PaymentContainer />
          ) : (
            <Box textAlign="center" mt={3}>
              <Typography variant="h6" gutterBottom>
                認証を行ってください。
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/verifing")}
              >
                認証する
              </Button>
            </Box>
          ))}
      </div>
    </>
  );
});

export default Profile;
