import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDoc, doc, collection, query, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../api/firebase';

const GroupMembers = ({ group }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        // メンバーの詳細情報を取得
        const membersDataPromises = group.members.map(async (member) => {
          const userDoc = await getDoc(doc(db, 'users', member.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();

            // DDP情報の取得
            const ddpRef = collection(db, `users/${member.uid}/ddps`);
            const q = query(ddpRef, limit(1));
            const querySnapshot = await new Promise((resolve, reject) => {
              onSnapshot(q, (snapshot) => {
                if (!snapshot.empty) {
                  resolve(snapshot);
                } else {
                  resolve(null);
                }
              }, reject);
            });

            const ddpData = querySnapshot ? querySnapshot.docs[0].data() : null;
            return { ...userData, ddp: ddpData };
          } else {
            return null;
          }
        });

        const membersData = await Promise.all(membersDataPromises);
        setMembers(membersData.filter(member => member !== null));
      } catch (err) {
        console.error('Error fetching members data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (group && group.members) {
      fetchMembersData();
    } else {
      console.error('Group or members data is not provided');
      setError('Group or members data is not provided');
      setLoading(false);
    }
  }, [group]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box>
      <Typography variant="h5">{group.name}</Typography>
      <Typography variant="body1">{group.description}</Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {members.map((member, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Avatar src={member.avatar} alt={member.name} sx={{ marginRight: 2 }} />
              <Typography>{member.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* {member.showAge && <Typography>年齢: {member.age}</Typography>}
              {member.showBloodType && <Typography>血液型: {member.bloodtype}</Typography>}
              {member.showSextype && <Typography>性別: {member.sextype}</Typography>} */}
              {member.ddp && (
                <>
                  <Typography>DDP: {member.ddp.ddp}</Typography>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default GroupMembers;
