import React, { useState, useEffect } from "react";
import { Typography, Button, Box } from "@mui/material";
import PrefectureDialog from "./PrefectureDialog";
import PrefectureChat from "./PrefectureChat";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";

const PrefectureChatPage = () => {
  const [user, setUser] = useState(null);
  const [prefectureDialogOpen, setPrefectureDialogOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        }
      }
    };

    fetchUser();
  }, []);

  const handlePrefectureUpdated = (message) => {
    console.log(message); // 必要に応じて通知や再レンダリングを実装
    setUser((prev) => ({ ...prev, isRegisterPrefecture: true }));
  };

  if (!user) {
    return <Typography>ユーザー情報を取得しています...</Typography>;
  }

  if (!user.prefecture) {
    return (
      <Box>
        <Typography variant="h6">居住地</Typography>
        {user.isRegisterPrefecture ? (
          <Box>
            <Typography
              variant="body1"
              className="text-2xl font-bold text-blue-500"
            >
              {user.prefecture}
            </Typography>
            <Typography variant="caption">
              ※一度決定した居住地は変更できません。
            </Typography>
          </Box>
        ) : (
          <Button
            variant="contained"
            onClick={() => setPrefectureDialogOpen(true)}
          >
            居住地を編集
          </Button>
        )}
        <PrefectureDialog
          open={prefectureDialogOpen}
          handleClose={() => setPrefectureDialogOpen(false)}
          onPrefectureUpdated={handlePrefectureUpdated}
        />
      </Box>
    );
  }

  const getPrefectureLogicalName = (prefecture) => {
    const prefectureMaps = {
      北海道: "hokkaido",
      青森県: "aomori",
      岩手県: "iwate",
      宮城県: "miyagi",
      秋田県: "akita",
      山形県: "yamagata",
      福島県: "fukushima",
      茨城県: "ibaraki",
      栃木県: "tochigi",
      群馬県: "gunma",
      埼玉県: "saitama",
      千葉県: "chiba",
      東京都: "tokyo",
      神奈川県: "kanagawa",
      新潟県: "niigata",
      富山県: "toyama",
      石川県: "ishikawa",
      福井県: "fukui",
      山梨県: "yamanashi",
      長野県: "nagano",
      岐阜県: "gifu",
      静岡県: "shizuoka",
      愛知県: "aichi",
      三重県: "mie",
      滋賀県: "shiga",
      京都府: "kyoto",
      大阪府: "osaka",
      兵庫県: "hyogo",
      奈良県: "nara",
      和歌山県: "wakayama",
      鳥取県: "tottori",
      島根県: "shimane",
      岡山県: "okayama",
      広島県: "hiroshima",
      山口県: "yamaguchi",
      徳島県: "tokushima",
      香川県: "kagawa",
      愛媛県: "ehime",
      高知県: "kochi",
      福岡県: "fukuoka",
      佐賀県: "saga",
      長崎県: "nagasaki",
      熊本県: "kumamoto",
      大分県: "oita",
      宮崎県: "miyazaki",
      鹿児島県: "kagoshima",
      沖縄県: "okinawa",
      // 特別な場合があればここに追加
      "海外（アメリカ）": "amerika",
      "海外（その他）": "foreign",
    };
    return prefectureMaps[prefecture] || prefecture;
  };

  return (
    <Box>
      <Typography variant="h5">{user.prefecture}チャット</Typography>
      <PrefectureChat groupId={getPrefectureLogicalName(user.prefecture)} />
    </Box>
  );
};

export default PrefectureChatPage;
