import React, { useState, useEffect } from "react";
import { auth, db } from "../api/firebase";
import { doc, getDoc } from "firebase/firestore";
import SurveyDialog from "./SurveyDialog";

const SurveyContainer = () => {
  const [isSurveyDialogOpen, setSurveyDialogOpen] = useState(false);
  const [isFabVisible, setFabVisible] = useState(false); // 初期状態は非表示
  const [isAnimated, setIsAnimated] = useState(true); // アニメーション制御

  useEffect(() => {
    const checkSurveyResponse = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error("No authenticated user");
          return;
        }

        // ユーザの payments フィールドを取得
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const payments = userData.payments || [];

          // payments の中に paymentPhase === "1" があるかを確認
          const hasPaymentPhase1 = payments.some(
            (payment) => payment.paymentPhase === "1"
          );

          if (hasPaymentPhase1) {
            // paymentPhase "1" があればアンケートの確認を行う
            const userSurveyQuery = doc(
              db,
              "users",
              userId,
              "fab",
              "202412Last"
            );
            const userSurveySnap = await getDoc(userSurveyQuery);

            setFabVisible(!userSurveySnap.exists()); // アンケート未回答の場合にボタンを表示
          }
        }
      } catch (error) {
        console.error("Failed to fetch survey response", error);
      }
    };

    checkSurveyResponse();

    // 1.5秒後にアニメーションを無効化
    const timer = setTimeout(() => {
      setIsAnimated(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <SurveyDialog
        open={isSurveyDialogOpen}
        onClose={() => setSurveyDialogOpen(false)}
        surveyId="202412Last"
        userId={auth.currentUser?.uid}
      />
      {isFabVisible && (
        <div
          style={{
            display: "inline-block",
            padding: "12px 24px",
            backgroundColor: "blue",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            animation: isAnimated
              ? "bounce 0.5s ease-in-out infinite"
              : "none", // アニメーションを1.5秒間適用
          }}
          onClick={() => setSurveyDialogOpen(true)}
        >
          アンケートのお願い
        </div>
      )}

      {/* アニメーションのスタイル */}
      <style>
        {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </div>
  );
};

export default SurveyContainer;
