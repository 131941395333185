import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Chip,
  Divider,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import {
  collection,
  getDocs,
  query,
  onSnapshot,
  where,
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useNavigate } from "react-router-dom"; // React Router

import { Favorite, FavoriteBorder } from "@mui/icons-material"; // ハートアイコンをインポート

const ReviewDDP = () => {
  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [prefectures, setPrefectures] = useState([]);
  const [ddps, setDdps] = useState([]);
  const [selectedDDP, setSelectedDDP] = useState(null);
  const [comment, setComment] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingComment, setEditingComment] = useState(null);

  const [dataCounts, setDataCounts] = useState({}); // 都道府県ごとの件数

  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setCommentDialogOpen(true);
  };

  const handleCommentDialogClose = () => {
    setCommentDialogOpen(false);
    setSelectedComment(null);
  };

  const navigate = useNavigate(); // ページ遷移用フック
  const currentUser = auth.currentUser?.uid;
  //   const currentUser = "匿名";

  useEffect(() => {
    const userRef = doc(db, "users", currentUser);
    const unsubscribe = onSnapshot(userRef, (docSnap) => {
      const userData = docSnap.data();

      // 条件を確認
      const hasAccess =
        userData?.isAdmin ||
        (userData?.payments?.some(
          (payment) =>
            payment.paymentPhase === "1" || payment.paymentPhase === "2"
        ) &&
          userData?.isVerified);

      // 条件を満たさない場合は/homeにリダイレクト
      if (!hasAccess) {
        navigate("/home");
      }
    });

    return () => unsubscribe(); // クリーンアップ
  }, [currentUser, navigate]);

  const maskName = (name) => {
    if (!name) return "";
    if (name.length <= 1) return "*";
    return name[0] + "*".repeat(name.length - 1);
  };

  const getCommentStyle = (count) => {
    if (count === 0) return { color: "gray", fontSize: "0.8rem" };
    if (count === 1) return { color: "blue", fontSize: "1rem" };
    if (count >= 2 && count <= 4) return { color: "green", fontSize: "1.2rem" };
    if (count >= 5 && count <= 9)
      return { color: "orange", fontSize: "1.5rem" };
    if (count >= 10) return { color: "red", fontSize: "1.8rem" };
    return {};
  };

  useEffect(() => {
    const fetchPrefectures = async () => {
      const q = query(collection(db, "reviewableDDPs"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const allData = snapshot.docs.map((doc) => doc.data());
        const uniquePrefectures = Array.from(
          new Set(allData.map((data) => data.prefecture))
        );
        setPrefectures(uniquePrefectures.sort());
      });

      return unsubscribe; // コンポーネントのクリーンアップ時に監視を解除
    };

    fetchPrefectures();
  }, []);
  const handlePrefectureChange = async (event) => {
    const selected = event.target.value;
    setSelectedPrefecture(selected);

    try {
      const q = query(
        collection(db, "reviewableDDPs"),
        where("prefecture", "==", selected)
      );
      const snapshot = await getDocs(q);
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        maskedName: maskName(doc.data().name),
        adviceCount: doc.data().advice ? doc.data().advice.length : 0,
        isCommentedByCurrentUser: doc.data().advice
          ? doc.data().advice.some((advice) => advice.createdBy === currentUser)
          : false,
      }));
      setDdps(data);
    } catch (error) {
      console.error("DDPの取得中にエラーが発生しました:", error);
    }
  };

  const handleDDPClick = (ddp) => {
    const ddpDocRef = doc(db, "reviewableDDPs", ddp.id);

    // Firestoreリアルタイム監視で`selectedDDP`を更新
    const unsubscribe = onSnapshot(ddpDocRef, (doc) => {
      if (doc.exists()) {
        setSelectedDDP({
          ...doc.data(),
          id: doc.id,
        });
      }
    });

    setDialogOpen(true);

    // ダイアログを閉じたら監視を解除
    return () => unsubscribe();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setComment("");
    setEditingComment(null);
  };

  const handleCommentSubmit = async () => {
    if (!comment.trim() || !selectedDDP || !currentUser) return;

    try {
      const newComment = {
        comment,
        createdBy: currentUser,
        createdAt: new Date().toISOString(),
      };

      const ddpDocRef = doc(db, "reviewableDDPs", selectedDDP.id);

      if (editingComment) {
        // Firestoreでコメントを更新
        await updateDoc(ddpDocRef, {
          advice: arrayUnion(newComment),
        });
        await updateDoc(ddpDocRef, {
          advice: arrayRemove(editingComment),
        });

        // `selectedDDP`を即時更新
        setSelectedDDP((prev) => ({
          ...prev,
          advice: prev.advice.map((advice) =>
            advice === editingComment ? newComment : advice
          ),
        }));
      } else {
        // Firestoreに新しいコメントを追加
        await updateDoc(ddpDocRef, {
          advice: arrayUnion(newComment),
        });

        // `selectedDDP`を即時更新
        setSelectedDDP((prev) => ({
          ...prev,
          advice: [...(prev.advice || []), newComment],
        }));
      }

      // `ddps`の状態も更新
      setDdps((prevDdps) =>
        prevDdps.map((ddp) =>
          ddp.id === selectedDDP.id
            ? {
                ...ddp,
                adviceCount: ddp.adviceCount + (editingComment ? 0 : 1),
                isCommentedByCurrentUser: true,
              }
            : ddp
        )
      );

      setEditingComment(null);
      handleDialogClose();
    } catch (error) {
      console.error("コメントの追加/編集中にエラーが発生しました:", error);
    }
  };

  const handleCommentEdit = (comment) => {
    setComment(comment.comment);
    setEditingComment(comment);
  };

  const handleCommentDelete = async (comment) => {
    if (!selectedDDP || !currentUser) return;

    try {
      const ddpDocRef = doc(db, "reviewableDDPs", selectedDDP.id);
      await updateDoc(ddpDocRef, {
        advice: arrayRemove(comment),
      });

      setDdps((prevDdps) =>
        prevDdps.map((ddp) =>
          ddp.id === selectedDDP.id
            ? { ...ddp, adviceCount: ddp.adviceCount - 1 }
            : ddp
        )
      );
    } catch (error) {
      console.error("コメントの削除中にエラーが発生しました:", error);
    }
  };

  const getUserComments = () => {
    if (!selectedDDP || !currentUser || !selectedDDP.advice) return [];
    return selectedDDP.advice.filter(
      (advice) => advice.createdBy === currentUser
    );
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const handleCommentDeleteRequest = (comment) => {
    setCommentToDelete(comment);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setCommentToDelete(null);
  };

  const handleCommentDeleteConfirm = async () => {
    if (!selectedDDP || !commentToDelete) return;

    try {
      const ddpDocRef = doc(db, "reviewableDDPs", selectedDDP.id);

      // Firestoreからコメントを削除
      await updateDoc(ddpDocRef, {
        advice: arrayRemove(commentToDelete),
      });

      // `selectedDDP`を更新
      setSelectedDDP((prev) => ({
        ...prev,
        advice: prev.advice.filter((advice) => advice !== commentToDelete),
      }));

      // `ddps`の状態を更新
      setDdps((prevDdps) =>
        prevDdps.map((ddp) =>
          ddp.id === selectedDDP.id
            ? {
                ...ddp,
                adviceCount: ddp.adviceCount - 1,
                isCommentedByCurrentUser:
                  selectedDDP.advice.filter(
                    (advice) =>
                      advice.createdBy === currentUser &&
                      advice !== commentToDelete
                  ).length > 0,
              }
            : ddp
        )
      );

      handleDeleteDialogClose();
    } catch (error) {
      console.error("コメントの削除中にエラーが発生しました:", error);
    }
  };

  const handleToggleLike = async (advice) => {
    if (!selectedDDP || !currentUser) return;

    try {
      const ddpDocRef = doc(db, "reviewableDDPs", selectedDDP.id);

      // トグル動作：いいねをオン/オフ
      const isLiked = advice.likes?.includes(currentUser);

      if (isLiked) {
        // いいねを取り消す
        await updateDoc(ddpDocRef, {
          advice: selectedDDP.advice.map((ad) =>
            ad === advice
              ? { ...ad, likes: ad.likes?.filter((uid) => uid !== currentUser) }
              : ad
          ),
        });
      } else {
        // いいねを追加
        await updateDoc(ddpDocRef, {
          advice: selectedDDP.advice.map((ad) =>
            ad === advice
              ? { ...ad, likes: [...(ad.likes || []), currentUser] }
              : ad
          ),
        });
      }

      // 状態を即時更新
      setSelectedDDP((prev) => ({
        ...prev,
        advice: prev.advice.map((ad) =>
          ad === advice
            ? {
                ...ad,
                likes: isLiked
                  ? ad.likes?.filter((uid) => uid !== currentUser)
                  : [...(ad.likes || []), currentUser],
              }
            : ad
        ),
      }));
    } catch (error) {
      console.error("いいねのトグル中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    const fetchDataCounts = async () => {
      const regions = [
        { name: "北海道", prefectures: ["北海道"] },
        {
          name: "東北地方",
          prefectures: [
            "青森県",
            "岩手県",
            "宮城県",
            "秋田県",
            "山形県",
            "福島県",
          ],
        },
        {
          name: "関東地方",
          prefectures: [
            "茨城県",
            "栃木県",
            "群馬県",
            "埼玉県",
            "千葉県",
            "東京都",
            "神奈川県",
          ],
        },
        {
          name: "中部地方",
          prefectures: [
            "新潟県",
            "富山県",
            "石川県",
            "福井県",
            "山梨県",
            "長野県",
            "岐阜県",
            "静岡県",
            "愛知県",
          ],
        },
        {
          name: "近畿地方",
          prefectures: [
            "三重県",
            "滋賀県",
            "京都府",
            "大阪府",
            "兵庫県",
            "奈良県",
            "和歌山県",
          ],
        },
        {
          name: "中国地方",
          prefectures: ["鳥取県", "島根県", "岡山県", "広島県", "山口県"],
        },
        {
          name: "四国地方",
          prefectures: ["徳島県", "香川県", "愛媛県", "高知県"],
        },
        {
          name: "九州地方",
          prefectures: [
            "福岡県",
            "佐賀県",
            "長崎県",
            "熊本県",
            "大分県",
            "宮崎県",
            "鹿児島県",
            "沖縄県",
          ],
        },
        {
          name: "海外",
          prefectures: [
            "海外（アメリカ以外に在住）",
            "海外（アメリカ合衆国在住）",
          ],
        },
        { name: "その他", prefectures: ["その他の地域"] },
      ];

      const counts = {};
      for (const region of regions) {
        for (const prefecture of region.prefectures) {
          const q = query(
            collection(db, "reviewableDDPs"),
            where("prefecture", "==", prefecture)
          );
          const snapshot = await getDocs(q);
          counts[prefecture] = snapshot.size; // 件数を取得
        }
      }
      setDataCounts(counts);
    };

    fetchDataCounts();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={4}
      p={4}
    >
      {/* 都道府県選択 */}

      <FormControl fullWidth sx={{ maxWidth: 300 }}>
        <InputLabel id="prefecture-select-label">地域を選択</InputLabel>
        <Select
          labelId="prefecture-select-label"
          value={selectedPrefecture}
          onChange={handlePrefectureChange}
          label="地域を選択"
        >
          {/* 北海道 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#e3f2fd",
              fontWeight: "bold",
              color: "#1976d2",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#bbdefb",
              },
            }}
          >
            北海道
          </MenuItem>
          <MenuItem value="北海道">
            北海道 ({dataCounts["北海道"] || 0} 件)
          </MenuItem>

          {/* 東北地方 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#ede7f6",
              fontWeight: "bold",
              color: "#5e35b1",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#d1c4e9",
              },
            }}
          >
            東北地方
          </MenuItem>
          {["青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県"].map(
            (prefecture) => (
              <MenuItem key={prefecture} value={prefecture}>
                {prefecture} ({dataCounts[prefecture] || 0} 件)
              </MenuItem>
            )
          )}

          {/* 関東地方 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#fce4ec",
              fontWeight: "bold",
              color: "#d81b60",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#f8bbd0",
              },
            }}
          >
            関東地方
          </MenuItem>
          {[
            "茨城県",
            "栃木県",
            "群馬県",
            "埼玉県",
            "千葉県",
            "東京都",
            "神奈川県",
          ].map((prefecture) => (
            <MenuItem key={prefecture} value={prefecture}>
              {prefecture} ({dataCounts[prefecture] || 0} 件)
            </MenuItem>
          ))}

          {/* 中部地方 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#e8f5e9",
              fontWeight: "bold",
              color: "#388e3c",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#c8e6c9",
              },
            }}
          >
            中部地方
          </MenuItem>
          {[
            "新潟県",
            "富山県",
            "石川県",
            "福井県",
            "山梨県",
            "長野県",
            "岐阜県",
            "静岡県",
            "愛知県",
          ].map((prefecture) => (
            <MenuItem key={prefecture} value={prefecture}>
              {prefecture} ({dataCounts[prefecture] || 0} 件)
            </MenuItem>
          ))}

          {/* 近畿地方 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#fff3e0",
              fontWeight: "bold",
              color: "#f57c00",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#ffe0b2",
              },
            }}
          >
            近畿地方
          </MenuItem>
          {[
            "三重県",
            "滋賀県",
            "京都府",
            "大阪府",
            "兵庫県",
            "奈良県",
            "和歌山県",
          ].map((prefecture) => (
            <MenuItem key={prefecture} value={prefecture}>
              {prefecture} ({dataCounts[prefecture] || 0} 件)
            </MenuItem>
          ))}

          {/* 中国地方 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#ede7f6",
              fontWeight: "bold",
              color: "#5e35b1",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#d1c4e9",
              },
            }}
          >
            中国地方
          </MenuItem>
          {["鳥取県", "島根県", "岡山県", "広島県", "山口県"].map(
            (prefecture) => (
              <MenuItem key={prefecture} value={prefecture}>
                {prefecture} ({dataCounts[prefecture] || 0} 件)
              </MenuItem>
            )
          )}

          {/* 四国地方 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#f3e5f5",
              fontWeight: "bold",
              color: "#8e24aa",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#e1bee7",
              },
            }}
          >
            四国地方
          </MenuItem>
          {["徳島県", "香川県", "愛媛県", "高知県"].map((prefecture) => (
            <MenuItem key={prefecture} value={prefecture}>
              {prefecture} ({dataCounts[prefecture] || 0} 件)
            </MenuItem>
          ))}

          {/* 九州地方 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#fff8e1",
              fontWeight: "bold",
              color: "#ffa000",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#ffecb3",
              },
            }}
          >
            九州地方
          </MenuItem>
          {[
            "福岡県",
            "佐賀県",
            "長崎県",
            "熊本県",
            "大分県",
            "宮崎県",
            "鹿児島県",
            "沖縄県",
          ].map((prefecture) => (
            <MenuItem key={prefecture} value={prefecture}>
              {prefecture} ({dataCounts[prefecture] || 0} 件)
            </MenuItem>
          ))}

          {/* 海外 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#e1f5fe",
              fontWeight: "bold",
              color: "#0288d1",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#b3e5fc",
              },
            }}
          >
            海外
          </MenuItem>
          <MenuItem value="海外（アメリカ以外に在住）">
            海外（アメリカ以外に在住） (
            {dataCounts["海外（アメリカ以外に在住）"] || 0} 件)
          </MenuItem>
          <MenuItem value="海外（アメリカ合衆国在住）">
            海外（アメリカ合衆国在住） (
            {dataCounts["海外（アメリカ合衆国在住）"] || 0} 件)
          </MenuItem>

          {/* その他 */}
          <MenuItem
            disabled
            sx={{
              backgroundColor: "#fbe9e7",
              fontWeight: "bold",
              color: "#d84315",
              fontSize: "1.1rem",
              "&:hover": {
                backgroundColor: "#ffccbc",
              },
            }}
          >
            その他
          </MenuItem>
          <MenuItem value="その他の地域">
            その他の地域 ({dataCounts["その他の地域"] || 0} 件)
          </MenuItem>
        </Select>
      </FormControl>

      {/* DDP一覧 */}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
        maxWidth={600}
      >
        {ddps.map((ddp) => (
          <Card
            key={ddp.id}
            sx={{
              display: "flex",
              alignItems: "center",
              p: 2,
              cursor: "pointer",
            }}
            onClick={() => handleDDPClick(ddp)}
          >
            <Avatar
              alt="Avatar"
              sx={{
                width: 56,
                height: 56,
                mr: 2,
                bgcolor: "primary.main",
                color: "white",
              }}
            >
              🗣️
            </Avatar>
            <CardContent>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {ddp.wish}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                提出者: {ddp.maskedName}
              </Typography>
              {/* コメント数の表示 */}
              <Typography variant="body2" sx={{ mt: 1 }}>
                コメント数：
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", display: "inline" }}
                  style={getCommentStyle(ddp.adviceCount)}
                >
                  {ddp.adviceCount}
                </Typography>
              </Typography>
              {/* コメント済みマーク */}
              {ddp.isCommentedByCurrentUser && (
                <Chip
                  label="コメント済"
                  color="success"
                  size="small"
                  sx={{ ml: 1, mt: 1 }}
                />
              )}
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* コメントダイアログ */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>コメントを追加</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="コメントを入力してください"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            キャンセル
          </Button>
          <Button
            onClick={handleCommentSubmit}
            color="primary"
            variant="contained"
          >
            {editingComment ? "コメントを編集" : "コメントを追加"}
          </Button>
        </DialogActions>

        {/* コメント一覧 */}
        <Divider sx={{ my: 2 }} />

        <Box
          p={2}
          sx={{
            maxHeight: "300px", // 最大高さを指定
            overflowY: "auto", // 縦スクロールを有効化
          }}
        >
          {selectedDDP?.advice?.map((advice, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={{
                mb: 2,
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* コメントテキスト部分 */}
              <Typography
                onClick={() => handleCommentClick(advice)}
                sx={{
                  cursor: "pointer", // ポインタ表示でクリック可能に見せる
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {advice.comment}
              </Typography>

              {/* 編集/削除 or いいね */}
              <Box display="flex" alignItems="center" gap={1}>
                {advice.createdBy === currentUser ? (
                  <>
                    <IconButton
                      color="primary"
                      onClick={() => handleCommentEdit(advice)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleCommentDeleteRequest(advice)}
                    >
                      <Delete />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton onClick={() => handleToggleLike(advice)}>
                      {advice.likes?.includes(currentUser) ? (
                        <Favorite color="error" />
                      ) : (
                        <FavoriteBorder />
                      )}
                    </IconButton>
                    <Typography>{advice.likes?.length || 0}</Typography>
                  </>
                )}
              </Box>
            </Card>
          ))}
        </Box>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>コメントを削除しますか？</DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="secondary">
            いいえ
          </Button>
          <Button
            onClick={handleCommentDeleteConfirm}
            color="error"
            variant="contained"
          >
            はい
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={commentDialogOpen}
        onClose={handleCommentDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>コメントの内容</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedComment?.comment}</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            作成者: {maskName(selectedComment?.createdBy)} {/* 名前をマスク */}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            作成日時: {new Date(selectedComment?.createdAt).toLocaleString()}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCommentDialogClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReviewDDP;
